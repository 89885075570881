import React from "react";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarStudent from "../../../components/template/SidebarStudent";

function MyReviews() {
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarStudent />
        <div className="main-profile-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header text-uppercase">All Review</div>
                  <div className="card-body">
                    <ul className="list-unstyled">
                      <li className="media">
                        <a href="../21/nishant.tripathi" target="_blank">
                          <img
                            className="mr-3 rounded-circle"
                            src="../file/20220305015725.png"
                            alt="user avatar"
                          />
                        </a>
                        <div
                          className="media-body"
                          style={{ marginTop: "0.8rem" }}
                        >
                          <h5 className="mt-0 mb-1">
                            <a href="../21/nishant.tripathi" target="_blank">
                              Nishant Tripathi
                            </a>{" "}
                            <i aria-hidden="true" className="fa fa-star" />
                            <i aria-hidden="true" className="fa fa-star" />
                            <i aria-hidden="true" className="fa fa-star" />
                            <i aria-hidden="true" className="fa fa-star" />
                            <i aria-hidden="true" className="fa fa-star" />
                          </h5>
                          very nice
                        </div>
                        <button
                          type="button"
                          className="btn btn-danger m-1 delete_data"
                          data-id={11}
                        >
                          <i aria-hidden="true" className="fa fa-trash fa-lg" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyReviews;
