import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import {
  inputFields,
  initialValues,
} from "../../../shared/enums/teaching_qualification_enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
import { upload } from "../../../shared/upload";
function TutorQualifications() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const submitFormClicked = async (values) => {
    if (featuredImage) {
      const profile_image = await upload(featuredImage);
      values.profile_image = profile_image;
    }
    if (gallery) {
      const resume = await upload(gallery[0]);
      values.resume = resume;
    }

    await dispatch(updateProfile({ tutor: { profile: values } }));
  };

  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarTutor id={user && user._id} />
        {!loading && user && user.user_type == "Tutor" ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TutorSteps activeProfile={"QUALIFICATION"} />
                      <div className="pt-4">
                        <h4>One last thing...</h4>
                        <p>We won't take a lot of your time, we promise!</p>
                      </div>
                      <AddForm
                        data={
                          user && user.tutor && user.tutor.profile
                            ? user.tutor.profile
                            : null
                        }
                        edit={true}
                        submitForm={submitFormClicked}
                        inputFields={inputFields}
                        initialValues={initialValues}
                        hideBackBtn={true}
                        featuredImage={featuredImage}
                        gallery={gallery}
                        setFeaturedImage={setFeaturedImage}
                        setGallery={setGallery}
                      ></AddForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default TutorQualifications;
