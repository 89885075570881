import React from "react";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";

function ForgetPassword() {
  return (
    <div className="main-wrapper">
      <Header />
      <div className="section section-padding">
        <div className="container">
          {/* Login & Register Wrapper Start */}
          <div className="login-register-wrapper">
            <div className="row gx-5">
              <div className="col-lg-6 mx-auto">
                {/* Login & Register Box Start */}
                <div className="login-register-box">
                  {/* Section Title Start */}
                  <div className="section-title">
                    <h2 className="title">Forget Password</h2>
                  </div>
                  {/* Section Title End */}
                  <div className="login-register-form">
                    <form action="#">
                      <div className="single-form">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Username or email "
                        />
                      </div>
                      <div className="single-form">
                        <button className="btn btn-primary btn-hover-heading-color w-100">
                          Send Reset Password Link
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* Login & Register Box End */}
              </div>
            </div>
          </div>
          {/* Login & Register Wrapper End */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ForgetPassword;
