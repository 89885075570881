import React from "react";
import HomeActionSection from "../../components/home/HomeActionSection";
import HomeBoards from "../../components/home/HomeBoards";
import HomeCategories from "../../components/home/HomeCategories";
import HomeCounterSection from "../../components/home/HomeCounterSection";
import HomeCoursesList from "../../components/home/HomeCoursesList";
import HomeFeatured from "../../components/home/HomeFeatured";
import HomeLanguageList from "../../components/home/HomeLanguageList";
import HomeLocationList from "../../components/home/HomeLocationList";
import HomeNewsletter from "../../components/home/HomeNewsletter";
import HomeSlider from "../../components/home/HomeSlider";
import HomeTestimonials from "../../components/home/HomeTestimonials";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";

function Home() {
  return (
    <div className="main-wrapper home">
      {/* Header */}
      <Header />
      <HomeSlider />
      <HomeFeatured />
      <HomeCategories />
      <HomeActionSection />
      <HomeCounterSection />
      <HomeBoards />
      <HomeCoursesList />
      <HomeLocationList />
      <HomeLanguageList />
      <HomeNewsletter />
      <HomeTestimonials />
      <Footer />
    </div>
  );
}

export default Home;
