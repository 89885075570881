import api from "../../domain/api";
import {
  GET_TUTORS,
  ADD_TUTOR,
  EDIT_TUTOR,
  GET_TUTOR,
  RESET_TUTOR,
  GET_ALL_TUTORS,
  ERROR_TUTOR,
} from "../types/tutor_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addTutor = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/tutors`, formData);
    dispatch({
      type: ADD_TUTOR,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getTutors =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
    exact_term = [],
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_TUTOR,
      });

      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);

      let newQParams = ``;
      const gender = urlParams.get("gender");
      if (gender) {
        newQParams += `&exact[gender]=${gender}`;
      }
      const state = urlParams.get("state");
      if (state) {
        newQParams += `&exact[tutor.address.state]=${state}`;
      }
      const city = urlParams.get("city");
      if (city) {
        newQParams += `&exact[tutor.address.city]=${city}`;
      }
      const area = urlParams.get("area");
      if (area) {
        newQParams += `&exact[tutor.address.area]=${area}`;
      }
      const experience = urlParams.get("experience");
      if (experience) {
        newQParams += `&exact[tutor.teaching_profile.total_teaching_experience]=${experience}`;
      }
      const tuition_type = urlParams.get("tuition_type");
      if (tuition_type) {
        if (tuition_type == "home") {
          newQParams += `&exact[tutor.teaching_preferences.home_tuition]=true`;
        }
        if (tuition_type == "group") {
          newQParams += `&exact[tutor.teaching_preferences.group_tuition]=true`;
        }
        if (tuition_type == "online") {
          newQParams += `&exact[tutor.teaching_preferences.online_tuition]=true`;
        }
      }
      const subject = urlParams.get("subject");
      if (subject) {
        newQParams += `&search[tutor.subjects.subject]=${subject}`;
      }
      const category = urlParams.get("category");
      if (category) {
        newQParams += `&search[tutor.subjects.category]=${category}`;
      }
      const { data } = await api.get(
        `/tutors?pageNumber=${pageNumber}${newQParams}`
      );

      dispatch({
        type: GET_TUTORS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getTutor = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_TUTOR,
    });
    const { data } = await api.get(`/tutors/${id}`);

    dispatch({
      type: GET_TUTOR,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const addReview = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/tutors/${id}/add-review`, formData);
    dispatch({
      type: EDIT_TUTOR,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteTutor = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/tutors/${id}`);
    dispatch(setAlert("Tutor Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllTutors = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/tutors/all`);

    dispatch({
      type: GET_ALL_TUTORS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_TUTOR });
};
