export const inputFields = {
  email: {
    type: "string",
    required: true,
    title: "Email",
    inputType: "text",
    col: 12,
  },

  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    col: 12,
  },
};
export const initialValues = {
  email: "",
  password: "",
};
