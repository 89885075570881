import React from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import { inputFields, initialValues } from "../../../shared/enums/address_enum";
import { useDispatch } from "react-redux";
import { register } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
import InstituteSteps from "../../../components/common/InstituteSteps";
import SidebarInstitute from "../../../components/template/SidebarInstitute";
function InstituteSubjects() {
  const dispatch = useDispatch();
  const submitFormClicked = async (values) => {
    await dispatch(register(values));
  };

  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarInstitute />
        <div className="main-profile-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <InstituteSteps activeProfile={"SUBJECT"} />
                    <div className="pt-4">
                      <h4>That was easy, Let's keep moving...</h4>
                      <p>
                        Add your subject one by one, This may take a while so
                        get a coffee maybe? ;)
                      </p>
                    </div>
                    {/* <AddForm
                      edit={false}
                      submitForm={submitFormClicked}
                      inputFields={inputFields}
                      initialValues={initialValues}
                      hideBackBtn={true}
                    ></AddForm> */}
                    <Formik
                      initialValues={{
                        name: "",
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);

                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <SelectBox
                                  label="Category"
                                  name="category"
                                  placeholder="Enter Category"
                                >
                                  <option value=""> Select Category </option>
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="subject"
                                  name="subject"
                                  placeholder="Enter subject"
                                >
                                  <option value=""> Select subject </option>
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="specialization"
                                  name="specialization"
                                  multiple
                                  placeholder="Enter specialization"
                                >
                                  <option value="">
                                    {" "}
                                    Select specialization{" "}
                                  </option>
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Board"
                                  name="board"
                                  placeholder="Enter Board"
                                  multiple
                                >
                                  <option value=""> Select Board </option>
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="stream"
                                  name="stream"
                                  placeholder="Enter stream"
                                  multiple
                                >
                                  <option value=""> Select stream </option>
                                </SelectBox>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 text-center m-3">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  {formik.isSubmitting
                                    ? "Processing..."
                                    : "EDIT"}
                                </button>
                                <Link
                                  to={"/tutor/subjects"}
                                  className="btn btn-secondary m-1"
                                >
                                  Next <i className="fa fa-angle-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default InstituteSubjects;
