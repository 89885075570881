import React from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import {
  inputFields,
  initialValues,
} from "../../../shared/enums/teaching_preferences_enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  MultiCheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";

function TutorPreferences() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const submitFormClicked = async (values) => {
    const formData = {
      max_travelling_distance:
        values.teaching_preferences.max_travelling_distance,
      average_fee_per_hour: values.teaching_preferences.average_fee_per_hour,
      home_tuition: values.teaching_preferences.home_tuition,
      group_tuition: values.teaching_preferences.group_tuition,
      online_tuition: values.teaching_preferences.online_tuition,
      travel_to_student_place:
        values.teaching_preferences.travel_to_student_place,
      student_must_travel: values.teaching_preferences.student_must_travel,
      prefer_days: values.teaching_preferences.prefer_days.toString(),
      prefer_time: values.teaching_preferences.prefer_time.toString(),
    };
    await dispatch(
      updateProfile({ tutor: { teaching_preferences: formData } })
    );
  };

  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarTutor id={user && user._id} />
        {!loading && user && user.user_type == "Tutor" ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TutorSteps activeProfile={"PREFERENCES"} />
                      <div className="pt-4">
                        <h4>We are racing towards the finish line...</h4>
                        <p>Tell us about your below preferences</p>
                        <p>Which mode of teaching you prefer?</p>
                      </div>

                      <Formik
                        initialValues={{
                          teaching_preferences: {
                            max_travelling_distance:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences
                                .max_travelling_distance
                                ? user.tutor.teaching_preferences
                                    .max_travelling_distance
                                : "",
                            average_fee_per_hour:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences
                                .average_fee_per_hour
                                ? user.tutor.teaching_preferences
                                    .average_fee_per_hour
                                : "",
                            home_tuition:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences.home_tuition
                                ? user.tutor.teaching_preferences.home_tuition
                                : false,
                            group_tuition:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences.group_tuition
                                ? user.tutor.teaching_preferences.group_tuition
                                : false,
                            online_tuition:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences.online_tuition
                                ? user.tutor.teaching_preferences.online_tuition
                                : false,
                            travel_to_student_place:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences
                                .travel_to_student_place
                                ? user.tutor.teaching_preferences
                                    .travel_to_student_place
                                : false,
                            student_must_travel:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences
                                .student_must_travel
                                ? user.tutor.teaching_preferences
                                    .student_must_travel
                                : false,
                            prefer_days:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences.prefer_days
                                ? user.tutor.teaching_preferences.prefer_days.split(
                                    ","
                                  )
                                : [],
                            prefer_time:
                              user.tutor &&
                              user.tutor.teaching_preferences &&
                              user.tutor.teaching_preferences.prefer_time
                                ? user.tutor.teaching_preferences.prefer_time.split(
                                    ","
                                  )
                                : [],
                          },
                        }}
                        validationSchema={Yup.object({})}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          submitFormClicked(values);
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row mx-auto">
                                <div className="col-md-12">
                                  <p>Which Mode Of Teaching You Prefer?</p>
                                </div>
                                <div className="col-md-3">
                                  <CheckBox name="teaching_preferences.home_tuition">
                                    Home Tuition
                                  </CheckBox>
                                </div>
                                <div className="col-md-3">
                                  <CheckBox name="teaching_preferences.group_tuition">
                                    Group Tuition
                                  </CheckBox>
                                </div>
                                <div className="col-md-3">
                                  <CheckBox name="teaching_preferences.online_tuition">
                                    Online Tuition
                                  </CheckBox>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <p>
                                    Travelling Preference (For Home & Group
                                    Tuition Only)
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <CheckBox name="teaching_preferences.travel_to_student_place">
                                    Travel to Student Place
                                  </CheckBox>
                                </div>
                                <div className="col-md-3">
                                  <CheckBox name="teaching_preferences.student_must_travel">
                                    Student Must Travel
                                  </CheckBox>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <MultiCheckBox
                                    name="teaching_preferences.prefer_days"
                                    label="Which days you prefer to teach? (Multiselect)"
                                    dropdown_options={[
                                      "Monday",
                                      "Tuesday",
                                      "Wednesday",
                                      "Thursday",
                                      "Friday",
                                      "Saturday",
                                      "Sunday",
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <MultiCheckBox
                                    label="PREFFERED TEACHING TIMINGS? (MULTISELECT)"
                                    name="teaching_preferences.prefer_time"
                                    dropdown_options={[
                                      "Early Morning",
                                      "Morning",
                                      "Afternoon",
                                      "Evening",
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="MAXIMUM TRAVELLING DISTANCE:"
                                    name="teaching_preferences.max_travelling_distance"
                                    type="text"
                                    placeholder="Enter MAXIMUM TRAVELLING DISTANCE:"
                                  />
                                </div>

                                <div className="col-md-6">
                                  <TextInput
                                    label="WHAT'S YOUR AVERAGE FEE PER HOUR?"
                                    name="teaching_preferences.average_fee_per_hour"
                                    type="text"
                                    placeholder="Enter WHAT'S YOUR AVERAGE FEE PER HOUR?"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "EDIT"}
                                  </button>
                                  <Link
                                    to={"/tutor/qualifications"}
                                    className="btn btn-secondary m-1"
                                  >
                                    Next <i className="fa fa-angle-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default TutorPreferences;
