import React from "react";

function HomeLanguageList() {
  return (
    <div className="section section-padding">
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title text-center">
          <h2 className="title">
            <span>Tutors for </span> Languages
          </h2>
          <p>Browse tutors for international &amp; regional languages</p>
        </div>
        <div className="language-flex">
          <div className="box-lang">
            <h2>English</h2>
          </div>
          <div className="box-lang">
            <h2>French</h2>
          </div>
          <div className="box-lang">
            <h2>Chinese</h2>
          </div>
          <div className="box-lang">
            <h2>Spanish</h2>
          </div>
          <div className="box-lang">
            <h2>German</h2>
          </div>
          <div className="box-lang">
            <h2>Other</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLanguageList;
