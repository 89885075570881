import React from "react";
import Icon1 from "../../assets/images/icon-1.png";
import Icon2 from "../../assets/images/icon-2.png";
import Icon3 from "../../assets/images/icon-3.png";
function HomeCounterSection() {
  return (
    <div className="section counter-section">
      <div className="container">
        {/* Counter Wrapper Start */}
        <div className="counter-wrapper">
          <div className="row row-cols-1 row-cols-sm-3">
            <div className="col">
              {/* Single Counter Start */}
              <div className="single-counter">
                <div className="counter-box">
                  <div className="counter-icon">
                    <img src={Icon1} alt="Icon" />
                  </div>
                  <div className="counter-content">
                    <span className="count value" data-count={360}>
                      360
                    </span>
                    <p>Hours Of Learning</p>
                  </div>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
            <div className="col">
              {/* Single Counter Start */}
              <div className="single-counter">
                <div className="counter-box">
                  <div className="counter-icon">
                    <img src={Icon2} alt="Icon" />
                  </div>
                  <div className="counter-content">
                    <span className="count value" data-count={360}>
                      360
                    </span>
                    <p>Hours Of Learning</p>
                  </div>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
            <div className="col">
              {/* Single Counter Start */}
              <div className="single-counter">
                <div className="counter-box">
                  <div className="counter-icon">
                    <img src={Icon3} alt="Icon" />
                  </div>
                  <div className="counter-content">
                    <span className="count value" data-count={360}>
                      360
                    </span>
                    <p>Course Series</p>
                  </div>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
          </div>
        </div>
        {/* Counter Wrapper End */}
      </div>
    </div>
  );
}

export default HomeCounterSection;
