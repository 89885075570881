import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllState,
  getDetailsByPincode,
  getCityByState,
  getAreaByState,
} from "../../store/actions/pincode_action";

export const useAllStates = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.pincode);
  useEffect(() => {
    dispatch(getAllState());
  }, []);
  return [data.states];
};
export const useCityByState = () => {
  const dispatch = useDispatch();
  const [selected_state, setSelected_state] = useState("");
  const data = useSelector((state) => state.pincode);
  useEffect(() => {
    if (selected_state && selected_state.length > 0) {
      dispatch(getCityByState(selected_state));
    }
  }, [selected_state]);
  return [setSelected_state, data.city];
};
export const useAreaByCity = () => {
  const dispatch = useDispatch();
  const [selected_state, setSelected_state] = useState("");
  const [selected_city, setSelected_city] = useState("");
  const data = useSelector((state) => state.pincode);
  useEffect(() => {
    if (
      selected_state &&
      selected_city &&
      selected_state.length > 0 &&
      selected_city.length > 0
    ) {
      dispatch(getAreaByState(selected_state, selected_city));
    }
  }, [selected_state]);
  return [setSelected_state, setSelected_city, data.area];
};
export const useSearchPincode = () => {
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState(null);
  const data = useSelector((state) => state.pincode);
  useEffect(() => {
    if (pincode && pincode.length > 4) {
      dispatch(getDetailsByPincode(pincode));
    }
  }, [pincode]);
  return [pincode, setPincode, data.by_pincode];
};
