import React from "react";
import Icon from "../../assets/images/icon-25.png";
import Banner from "../../assets/images/banner-03.png";
import { Link } from "react-router-dom";
function HomeLocationList() {
  return (
    <div className="section section-padding">
      <div className="container">
        {/* Section Title Start */}
        {/* Section Title End */}
        {/* Courses Wrapper Start */}
        <div className="courses-wrapper">
          <div className="row">
            <div className="col-lg-3">
              <div className="courses-banner">
                <Link to="/tutors">
                  <img src={Banner} alt="banner" />
                </Link>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row mb-4">
                <h4>Locations- Tutors near me</h4>
                <p>
                  Trusted &amp; verified Private home tuition &amp; online
                  tuition near you
                </p>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=Maharashtra&city=MUMBAI`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">MUMBAI</span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=Delhi`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">DELHI</span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=West+Bengal&city=KOLKATA`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title"> KOLKATA</span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=Karnataka&city=BENGALURU`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">BENGALURU </span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=Gujarat&city=AHMEDABAD`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">AHMEDABAD</span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=Tamil+Nadu&city=CHENNAI`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">CHENNAI</span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors?state=Telangana&city=HYDERABAD`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">HYDERABAD</span>
                    </Link>{" "}
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <Link to={`/tutors`}>
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">ALL</span>
                    </Link>
                  </div>
                  {/* Category Item End */}
                </div>
              </div>
              <div className="row mt-2">
                <h4>Tuition for Entrance Exams</h4>
                <p>
                  Get Tutors to help you sail through toughest of competitive
                  exams
                </p>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">JEE</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">NEET</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title"> MBA</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">TOEFL </span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">IELTS</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">SAT</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">GMAT</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Category Item Start */}
                  <div className="single-category-item location">
                    <a href="course-grid.html">
                      <img className="item-icon" src={Icon} alt />
                      <span className="title">ALL</span>
                    </a>
                  </div>
                  {/* Category Item End */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                {/* Category Item Start */}
                <div className="single-category-item location">
                  <a href="course-grid.html">
                    <img className="item-icon" src={Icon} alt />
                    <span className="title">ALL</span>
                  </a>
                </div>
                {/* Category Item End */}
              </div>
            </div>
          </div>
        </div>
        {/* Courses Wrapper End */}
      </div>
    </div>
  );
}

export default HomeLocationList;
