import React from "react";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarStudent from "../../../components/template/SidebarStudent";

function PaymentHistory() {
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarStudent />
        <div className="main-profile-content">
          <div className="container-fluid">
            {/* Breadcrumb*/}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    Total Tutor Contact Unlocks Availabel: 2
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="example_wrapper"
                        className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="example"
                              className="table table-bordered dataTable no-footer"
                              role="grid"
                              aria-describedby="example_info"
                              style={{ width: 1184 }}
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    colSpan={7}
                                    className="main-heading"
                                    rowSpan={1}
                                  >
                                    Payment Details
                                  </th>
                                  <th
                                    colSpan={2}
                                    className="main-heading"
                                    rowSpan={1}
                                  >
                                    Plan Details
                                  </th>
                                </tr>
                                <tr role="row">
                                  <th
                                    className="sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-sort="ascending"
                                    aria-label="Order Id: activate to sort column descending"
                                    style={{ width: 59 }}
                                  >
                                    Order Id
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Payment Id: activate to sort column ascending"
                                    style={{ width: 76 }}
                                  >
                                    Payment Id
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Total Amount (Incl. tax): activate to sort column ascending"
                                    style={{ width: 99 }}
                                  >
                                    Total Amount
                                    <br /> (Incl. tax)
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Payment Mode: activate to sort column ascending"
                                    style={{ width: 99 }}
                                  >
                                    Payment Mode
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Payment Status: activate to sort column ascending"
                                    style={{ width: 112 }}
                                  >
                                    Payment Status
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Date: activate to sort column ascending"
                                    style={{ width: 33 }}
                                  >
                                    Date
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Time: activate to sort column ascending"
                                    style={{ width: 32 }}
                                  >
                                    Time
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Plan Name: activate to sort column ascending"
                                    style={{ width: 75 }}
                                  >
                                    Plan Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Tutor Contact: activate to sort column ascending"
                                    style={{ width: 105 }}
                                  >
                                    Tutor Contact
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="odd">
                                  <td
                                    valign="top"
                                    colSpan={9}
                                    className="dataTables_empty"
                                  >
                                    No data available in table
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="example_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 0 to 0 of 0 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="example_paginate"
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="example_previous"
                                >
                                  <a
                                    href="#"
                                    aria-controls="example"
                                    data-dt-idx={0}
                                    tabIndex={0}
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="example_next"
                                >
                                  <a
                                    href="#"
                                    aria-controls="example"
                                    data-dt-idx={1}
                                    tabIndex={0}
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Row*/}
            {/*start overlay*/}
            <div className=" overlay toggle-menu"></div>
            {/*end overlay*/}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PaymentHistory;
