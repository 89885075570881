import React, { useEffect, useState } from "react";
import Pagination from "../../components/common/Pagination";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import TutorCard from "../../components/tutor/TutorCard";
import TutorGrid from "../../components/tutor/TutorGrid";
import { useAllTutors } from "../../shared/hooks/UseTutor";
import { useQuery } from "../../domain/useQuery";
import {
  useAllStates,
  useCityByState,
  useAreaByCity,
} from "../../shared/hooks/UsePincodes";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";
import { useGetSubjectByCategory } from "../../shared/hooks/UseSubject";

function ListTutors() {
  let query = useQuery();
  // const selectedState = query.get("state");
  const [states] = useAllStates();
  const [category] = useSelectAllCategory();
  const { all_categorys } = category;
  const [selectedCategory, setSelectedCategory, subject] =
    useGetSubjectByCategory();
  const { subjects } = subject;
  const [
    data,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
    handleSidebarChange,
    removeSidebarParams,
  ] = useAllTutors();
  const { loading, tutors, total_tutors, page, pages } = data;
  const [activeTab, setActiveTab] = useState("LIST");
  const [setSelected_state, cities] = useCityByState();
  const [setSelectedNew, setSelected_City, areas] = useAreaByCity();
  useEffect(() => {
    setSelected_state(query.get("state"));
  }, [query.get("state")]);
  useEffect(() => {
    if ((query.get("state"), query.get("city"))) {
      setSelectedNew(query.get("state"));
      setSelected_City(query.get("city"));
    }
  }, [query.get("state"), query.get("city")]);

  return (
    <div className="main-wrapper tutor-list">
      <Header />
      <div className="section section-padding">
        <div className="container">
          {/* Course List Wrapper Start */}
          <div className="course-list-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                {/* Course Top Bar Start */}
                <div className="course-top-bar">
                  <div className="course-top-text">
                    <p>
                      We found <span>{total_tutors}</span> Courses For You
                    </p>
                  </div>
                  <div class="course-top-inner">
                    <ul class="course-top-menu">
                      <span class="label">View</span>
                      <ul class="nav">
                        {/* <li>
                          <button
                            className={activeTab == "GRID" ? "active" : ""}
                            onClick={() => setActiveTab("GRID")}
                          >
                            <i class="fa fa-th-large"></i>
                          </button>
                        </li> */}
                        <li>
                          <button
                            class={activeTab == "LIST" ? "active" : ""}
                            onClick={() => setActiveTab("LIST")}
                          >
                            <i class="fa fa-th-list"></i>
                          </button>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
                {/* Course Top Bar End */}
                <div className="tab-content">
                  <div
                    className={
                      activeTab == "GRID"
                        ? "tab-pane fade active"
                        : "tab-pane fade"
                    }
                    id="grid"
                  >
                    <div className="row">
                      {tutors &&
                        tutors.map((tutor) => {
                          return (
                            <div className="col-lg-4 col-sm-6">
                              {/* Single Courses Start */}
                              <TutorGrid tutor={tutor} />
                              {/* Single Courses End */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    className={
                      activeTab == "LIST"
                        ? "tab-pane fade active"
                        : "tab-pane fade"
                    }
                    id="list"
                  >
                    {/* Course List Start */}
                    <div className="course-list-items">
                      {tutors &&
                        tutors.map((tutor) => {
                          // console.log("TUTOR", tutor);
                          return <TutorCard tutor={tutor} />;
                        })}
                    </div>
                    {/* Course List End */}
                  </div>
                </div>
                <Pagination
                  data={tutors}
                  page={page}
                  pages={pages}
                  count={total_tutors}
                  setPage={setPageNumber}
                  loading={loading}
                />
                {/* Page Pagination End */}
              </div>
              <div className="col-lg-3">
                {/* Sidebar Wrapper Start */}
                <div className="sidebar-wrap-02 mt-70">
                  <div className="">
                    <h3 className="widget-title">Gender</h3>
                    <div className="widget-checkbox">
                      <ul className="checkbox-list">
                        <li className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox1"
                            checked={query.get("gender") == "Female"}
                            onChange={(e) =>
                              e.target.checked &&
                              handleSidebarChange("gender", "Female")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox1"
                          >
                            Female
                          </label>
                        </li>
                        <li className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox2"
                            checked={query.get("gender") == "Male"}
                            onChange={(e) =>
                              e.target.checked &&
                              handleSidebarChange("gender", "Male")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox2"
                          >
                            Male
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="sidebar-wrap-02">
                  <div className="">
                    <h3 className="widget-title">Location</h3>
                    <div className="location-f">
                      <form>
                        <div class="form-group">
                          <select
                            class="form-control"
                            value={query.get("state")}
                            onChange={(e) => {
                              removeSidebarParams("city");
                              removeSidebarParams("area");
                              handleSidebarChange("state", e.target.value);
                            }}
                          >
                            <option selected>Select State</option>
                            {states &&
                              states.map((item) => {
                                return <option value={item}> {item} </option>;
                              })}
                          </select>
                        </div>
                        <div class="form-group">
                          <select
                            class="form-control"
                            value={query.get("city") ? query.get("city") : ""}
                            onChange={(e) => {
                              removeSidebarParams("area");
                              handleSidebarChange("city", e.target.value);
                            }}
                          >
                            <option selected>Select City</option>
                            {cities &&
                              cities.map((item) => {
                                return <option value={item}>{item}</option>;
                              })}
                          </select>
                        </div>
                        <div class="form-group">
                          <select
                            class="form-control"
                            value={query.get("area") ? query.get("area") : ""}
                            onChange={(e) =>
                              handleSidebarChange("area", e.target.value)
                            }
                          >
                            <option selected>Select Area</option>
                            {areas &&
                              areas.map((item) => {
                                return <option value={item}>{item}</option>;
                              })}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="sidebar-wrap-02 ">
                  <div className="">
                    <h3 className="widget-title">Filter by Experience</h3>
                    <div className="range-f">
                      <p> {query.get("experience")} </p>
                      <div className="form-group">
                        <input
                          type="range"
                          className="form-control-range"
                          id="formControlRange"
                          min="0"
                          max="10"
                          step="1"
                          value={
                            query.get("experience")
                              ? query.get("experience")
                              : 0
                          }
                          onChange={(e) =>
                            handleSidebarChange("experience", e.target.value)
                          }
                        />
                        <div className="range-flex">
                          <div>
                            <span>0</span>
                          </div>
                          <div>
                            <span>10</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-wrap-02 ">
                  <div className="">
                    <h3 className="widget-title">Category</h3>
                    <div className="widget-checkbox">
                      <ul className="checkbox-list">
                        {all_categorys &&
                          all_categorys.map((item, index) => {
                            return (
                              <li
                                className="form-check"
                                key={`category-${index}`}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={query.get("category") == item.name}
                                  onChange={(e) => {
                                    e.target.checked &&
                                      handleSidebarChange(
                                        "category",
                                        item.name
                                      );
                                    setSelectedCategory(item._id);
                                  }}
                                  id={item.name}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={item.name}
                                >
                                  {item.name}
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="sidebar-wrap-02 ">
                  <div className="">
                    <h3 className="widget-title">Teaching Mode</h3>
                    <div className="widget-checkbox">
                      <ul className="checkbox-list">
                        <li className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue
                            id="home_tuition"
                            checked={query.get("tuition_type") == "home"}
                            onChange={(e) =>
                              e.target.checked &&
                              handleSidebarChange("tuition_type", "home")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="home_tuition"
                          >
                            Home Tuition
                          </label>
                        </li>
                        <li className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={query.get("tuition_type") == "group"}
                            onChange={(e) =>
                              e.target.checked &&
                              handleSidebarChange("tuition_type", "group")
                            }
                            id="group_tuition"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="group_tuition"
                          >
                            Group Tuition
                          </label>
                        </li>
                        <li className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={query.get("tuition_type") == "online"}
                            onChange={(e) =>
                              e.target.checked &&
                              handleSidebarChange("tuition_type", "online")
                            }
                            id="online_tuition"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="online_tuition"
                          >
                            Online Tuition
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="sidebar-wrap-02 ">
                  <div className="">
                    <h3 className="widget-title">Subjects</h3>
                    <div className="widget-checkbox">
                      <ul className="checkbox-list">
                        {subjects &&
                          subjects.map((item) => {
                            return (
                              <li className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={query.get("subject") == item.name}
                                  onChange={(e) =>
                                    e.target.checked &&
                                    handleSidebarChange("subject", item.name)
                                  }
                                  id={item.name}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={item.name}
                                >
                                  {item.name}
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Sidebar Wrapper End */}
              </div>
            </div>
          </div>
          {/* Course List Wrapper End */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ListTutors;
