let years = [];

for (let i = 1960; i <= new Date().getFullYear(); i++) {
  years.push({ label: i, value: i });
}

export const inputFields = {
  highest_qualification: {
    type: "select",
    required: true,
    title: "What's your highest qualification?",
    options: [
      { label: "Ph.D.", value: "Ph.D." },
      { label: "Post Graduation", value: "Post Graduation" },
      { label: "Graduation", value: "Graduation" },
      { label: "Diploma", value: "Diploma" },
      { label: "Higher Secondary / 12th", value: "Higher Secondary / 12th" },
      { label: "Secondary / 10th", value: "Secondary / 10th" },
    ],

    col: 6,
  },
  qualification_status: {
    type: "select",
    required: true,
    title: "Qualification Status",
    options: [
      { label: "Completed", value: "Completed" },
      { label: "Pursuing", value: "Pursuing" },
    ],
    col: 6,
  },
  qualification_name: {
    type: "string",
    required: true,
    title: "Qualification Name",

    col: 12,
  },
  qualification_year: {
    type: "select",
    required: true,
    title: "Qualification Year",
    options: years,

    col: 6,
  },
  school_medium: {
    type: "select",
    required: true,
    title: "Your school medium in 10th std. :",
    options: [
      { label: "English", value: "English" },
      { label: "Hindi", value: "Hindi" },
      { label: "Bengali", value: "Bengali" },
      { label: "Marathi", value: "Marathi" },
      { label: "Telugu", value: "Telugu" },
      { label: "Tamil", value: "Tamil" },
      { label: "Gujrati", value: "Gujrati" },
      { label: "Urdu", value: "Urdu" },
      { label: "Kannada", value: "Kannada" },
      { label: "Odia", value: "Odia" },
      { label: "Malyalam", value: "Malyalam" },
      { label: "Punjabi", value: "Punjabi" },
    ],

    col: 6,
  },

  short_profile: {
    type: "text",
    required: true,
    title: "Short Profile",
    inputType: "text",
    col: 12,
  },
  description: {
    type: "text",
    required: true,
    title: "Profile Description",
    inputType: "text",
    col: 12,
  },
  profile_image: {
    type: "file",
    required: false,
    title: "Cover Image",
  },
  resume: {
    type: "gallery",
    required: false,
    title: "Resume",
  },
};
export const initialValues = {
  highest_qualification: "",
  qualification_status: "",
  qualification_name: "",
  qualification_year: "",
  school_medium: "",
  short_profile: "",
  description: "",
};
