import React from "react";
import Category1 from "../../assets/images/category-1.jpg";
import Category2 from "../../assets/images/category-2.jpg";
import Category3 from "../../assets/images/category-3.jpg";
import Category4 from "../../assets/images/category-4.jpg";
import Category5 from "../../assets/images/category-5.jpg";
import Category6 from "../../assets/images/category-6.jpg";
function HomeCoursesList() {
  return (
    <div className="section section-padding bg-color-1">
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title text-center">
          <h2 className="title">
            <span>Top categories</span> you can enroll now
          </h2>
        </div>
        {/* Section Title End */}
        {/* Category Wrapper Start */}
        <div className="category-wrapper">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              {/* Single Category Start */}
              <div className="single-category">
                <div className="category-image">
                  <a href="course-grid.html">
                    <img src={Category1} alt="Category" />
                  </a>
                </div>
                <div className="category-content">
                  <div className="category-title">
                    <h4 className="title">
                      <a href="course-grid.html">Course-1</a>
                    </h4>
                    <p>10 Course</p>
                  </div>
                  <a href="course-grid.html" className="category-link">
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              {/* Single Category End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Category Start */}
              <div className="single-category">
                <div className="category-image">
                  <a href="course-grid.html">
                    <img src={Category2} alt="Category" />
                  </a>
                </div>
                <div className="category-content">
                  <div className="category-title">
                    <h4 className="title">
                      <a href="course-grid.html">Course-2</a>
                    </h4>
                    <p>10 Course</p>
                  </div>
                  <a href="course-grid.html" className="category-link">
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              {/* Single Category End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Category Start */}
              <div className="single-category">
                <div className="category-image">
                  <a href="course-grid.html">
                    <img src={Category3} alt="Category" />
                  </a>
                </div>
                <div className="category-content">
                  <div className="category-title">
                    <h4 className="title">
                      <a href="course-grid.html">Course-3</a>
                    </h4>
                    <p>10 Course</p>
                  </div>
                  <a href="course-grid.html" className="category-link">
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              {/* Single Category End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Category Start */}
              <div className="single-category">
                <div className="category-image">
                  <a href="course-grid.html">
                    <img src={Category4} alt="Category" />
                  </a>
                </div>
                <div className="category-content">
                  <div className="category-title">
                    <h4 className="title">
                      <a href="course-grid.html">Course-4</a>
                    </h4>
                    <p>10 Course</p>
                  </div>
                  <a href="course-grid.html" className="category-link">
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              {/* Single Category End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Category Start */}
              <div className="single-category">
                <div className="category-image">
                  <a href="course-grid.html">
                    <img src={Category5} alt="Category" />
                  </a>
                </div>
                <div className="category-content">
                  <div className="category-title">
                    <h4 className="title">
                      <a href="course-grid.html">Course-5</a>
                    </h4>
                    <p>10 Course</p>
                  </div>
                  <a href="course-grid.html" className="category-link">
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              {/* Single Category End */}
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* Single Category Start */}
              <div className="single-category">
                <div className="category-image">
                  <a href="course-grid.html">
                    <img src={Category6} alt="Category" />
                  </a>
                </div>
                <div className="category-content">
                  <div className="category-title">
                    <h4 className="title">
                      <a href="course-grid.html">Course-6</a>
                    </h4>
                    <p>10 Course</p>
                  </div>
                  <a href="course-grid.html" className="category-link">
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              {/* Single Category End */}
            </div>
          </div>
        </div>
        {/* Category Wrapper End */}
      </div>
    </div>
  );
}

export default HomeCoursesList;
