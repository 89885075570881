import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import { inputFields, initialValues } from "../../../shared/enums/address_enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { useSelectAllCategory } from "../../../shared/hooks/UseCategory";
import { useGetSubjectByCategory } from "../../../shared/hooks/UseSubject";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  MultiCheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
function TutorSubjects() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const [category] = useSelectAllCategory();
  const [activeCategory, setActiveCategory, subject_data] =
    useGetSubjectByCategory();
  const { all_categorys } = category;
  const { subjects } = subject_data;
  const [activeSubject, setActiveSubject] = useState(null);
  const [activeSubjectObject, setActiveSubjectObject] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState([]);
  useEffect(() => {
    if (category) {
      if (subjects) {
        if (activeSubject) {
          const filterData = subjects.filter(
            (item) => item._id == activeSubject
          );
          if (filterData && filterData.length > 0) {
            setActiveSubjectObject(filterData[0]);
          } else {
            setActiveSubjectObject(null);
          }
        } else {
          setActiveSubjectObject(null);
        }
      } else {
        setActiveSubjectObject(null);
      }
    } else {
      setActiveSubjectObject(null);
    }
  }, [activeSubject, subjects, category]);

  useEffect(() => {
    if (user.tutor && user.tutor.subjects) {
      setSelectedSubjects(
        user.tutor.subjects.subject
          ? user.tutor.subjects.subject.split(",")
          : []
      );
      setSelectedCategories(
        user.tutor.subjects.category
          ? user.tutor.subjects.category.split(",")
          : []
      );
      setSelectedSpecialization(
        user.tutor.subjects.specialization
          ? user.tutor.subjects.specialization.split(",")
          : []
      );
      setSelectedStreams(
        user.tutor.subjects.stream ? user.tutor.subjects.stream.split(",") : []
      );
      setSelectedBoards(
        user.tutor.subjects.board ? user.tutor.subjects.board.split(",") : []
      );
    }
  }, [user.tutor]);

  const getCategoryName = (cat) => {
    const filterData = all_categorys.filter((item) => item._id == cat);
    if (filterData && filterData.length > 0) {
      return filterData[0].name;
    }
  };
  const getSubjectName = (subject) => {
    const filterData = subjects.filter((item) => item._id == subject);
    if (filterData && filterData.length > 0) {
      return filterData[0].name;
    }
  };

  const submitFormClicked = async (values) => {
    // await dispatch(register(values));
    const specializationNew = [
      ...new Set([...selectedSpecialization, ...values.specialization]),
    ];
    const categoryNew = [
      ...new Set([
        ...selectedCategories,
        getCategoryName(values.category_selected),
      ]),
    ];
    const subjectNew = [
      ...new Set([...selectedSubjects, getSubjectName(values.subject)]),
    ];
    const boardNew = [...new Set([...selectedBoards, ...values.board])];
    const streamNew = [...new Set([...selectedStreams, ...values.stream])];
    const formData = {
      subject: subjectNew.toString(),
      category: categoryNew.toString(),
      specialization: specializationNew.toString(),
      board: boardNew.toString(),
      stream: streamNew.toString(),
    };
    console.log("FormData", formData);
    await dispatch(updateProfile({ tutor: { subjects: formData } }));
  };
  const deleteCategory = (cat) => {
    console.log("CAT", cat);
    const categoryData =
      user &&
      user.tutor &&
      user.tutor.subjects &&
      user.tutor.subjects.subject &&
      user.tutor.subjects.subject.split(",");
    const filterData =
      categoryData && categoryData.filter((item) => item != cat);
    console.log(filterData);
    const allData = user.tutor.subjects;
    allData.subject = filterData.toString();

    dispatch(
      updateProfile({
        tutor: { subjects: allData },
      })
    );
  };
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarTutor id={user && user._id} />
        {!loading && user && user.user_type == "Tutor" ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TutorSteps activeProfile={"SUBJECT"} />
                      <div className="pt-4">
                        <h4>That was easy, Let's keep moving...</h4>
                        <p>
                          Add your subject one by one, This may take a while so
                          get a coffee maybe? ;)
                        </p>
                      </div>
                      <div>
                        <div className="subject-list">
                          {user.tutor &&
                            user.tutor.subjects &&
                            user.tutor.subjects.subject &&
                            user.tutor.subjects.subject.split(",") &&
                            user.tutor.subjects.subject
                              .split(",")
                              .map((item) => {
                                return (
                                  <div className="subject-single">
                                    <div> {item} </div>
                                    <div>
                                      <button
                                        className="btn btn-sm text-danger"
                                        onClick={() => deleteCategory(item)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                      {/* <AddForm
                      edit={false}
                      submitForm={submitFormClicked}
                      inputFields={inputFields}
                      initialValues={initialValues}
                      hideBackBtn={true}
                    ></AddForm> */}
                      <Formik
                        initialValues={{
                          category_selected: "",
                          subject: "",
                          specialization: "",
                          board: "",
                          stream: "",
                        }}
                        validationSchema={Yup.object({
                          category_selected: Yup.string().required("Required"),
                          subject: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await submitFormClicked(values);
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <SelectBox
                                    label="Category"
                                    name="category"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "category",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "category_selected",
                                        e.target.value
                                      );

                                      setActiveCategory(e.target.value);
                                      formik.setFieldValue("subject", "");
                                      formik.setFieldValue(
                                        "specialization",
                                        ""
                                      );
                                      formik.setFieldValue("board", "");
                                      formik.setFieldValue("stream", "");
                                    }}
                                    placeholder="Enter Category"
                                  >
                                    <option value=""> Select Category </option>
                                    {all_categorys &&
                                      all_categorys.map((item) => {
                                        return (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    <option value=""></option>
                                  </SelectBox>
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                    label="subject"
                                    name="subject"
                                    placeholder="Enter subject"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "subject",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "specialization",
                                        ""
                                      );
                                      formik.setFieldValue("board", "");
                                      formik.setFieldValue("stream", "");
                                      setActiveSubject(e.target.value);
                                    }}
                                  >
                                    <option value=""> Select subject </option>
                                    {activeCategory &&
                                      subjects &&
                                      subjects.map((item) => {
                                        return (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </SelectBox>
                                </div>
                                {activeSubjectObject &&
                                  activeSubjectObject.specialization &&
                                  activeSubjectObject.specialization.length >
                                    0 && (
                                    <div className="col-md-12">
                                      <MultiCheckBox
                                        name="specialization"
                                        label="Specialization"
                                        dropdown_options={
                                          activeSubjectObject.specialization
                                        }
                                      />
                                    </div>
                                  )}
                                {activeSubjectObject &&
                                  activeSubjectObject.board &&
                                  activeSubjectObject.board.length > 0 && (
                                    <div className="col-md-12">
                                      <MultiCheckBox
                                        name="board"
                                        label="board"
                                        dropdown_options={
                                          activeSubjectObject.board
                                        }
                                      />
                                    </div>
                                  )}
                                {activeSubjectObject &&
                                  activeSubjectObject.stream &&
                                  activeSubjectObject.stream.length > 0 && (
                                    <div className="col-md-12">
                                      <MultiCheckBox
                                        name="stream"
                                        label="stream"
                                        dropdown_options={
                                          activeSubjectObject.stream
                                        }
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "EDIT"}
                                  </button>
                                  <Link
                                    to={"/tutor/preferences"}
                                    className="btn btn-secondary m-1"
                                  >
                                    Next <i className="fa fa-angle-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default TutorSubjects;
