import React from "react";
import AddForm from "../../components/common/AddForm";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import { inputFields, initialValues } from "../../shared/enums/profile_enum";
import { useDispatch } from "react-redux";
import { register } from "../../store/actions/auth";

function Register() {
  const dispatch = useDispatch();
  const submitFormClicked = async (values) => {
    await dispatch(register(values));
  };

  return (
    <div className="main-wrapper">
      <Header />
      <div className="section section-padding">
        <div className="container">
          {/* Login & Register Wrapper Start */}
          <div className="login-register-wrapper">
            <div className="row gx-5">
              <div className="col-lg-6 mx-auto">
                {/* Login & Register Box Start */}
                <div className="login-register-box">
                  {/* Section Title Start */}
                  <div className="section-title">
                    <h2 className="title">Register</h2>
                  </div>
                  {/* Section Title End */}
                  <div className="login-register-form">
                    <AddForm
                      edit={false}
                      submitForm={submitFormClicked}
                      inputFields={inputFields}
                      initialValues={initialValues}
                      hideBackBtn={true}
                    />
                  </div>
                </div>
                {/* Login & Register Box End */}
              </div>
            </div>
          </div>
          {/* Login & Register Wrapper End */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Register;
