import React from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import {
  inputFields,
  initialValues,
} from "../../../shared/enums/teaching_preferences_enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
import InstituteSteps from "../../../components/common/InstituteSteps";
import SidebarInstitute from "../../../components/template/SidebarInstitute";
function InstituteBatchDetails() {
  const { user, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const submitFormClicked = async (values) => {
    await dispatch(updateProfile({ institute: values }));
  };
  const teachingMeadium = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Bengali", value: "Bengali" },
    { label: "Marathi", value: "Marathi" },
    { label: "Telugu", value: "Telugu" },
    { label: "Tamil", value: "Tamil" },
    { label: "Gujrati", value: "Gujrati" },
    { label: "Urdu", value: "Urdu" },
    { label: "Kannada", value: "Kannada" },
    { label: "Odia", value: "Odia" },
    { label: "Malyalam", value: "Malyalam" },
    { label: "Punjabi", value: "Punjabi" },
  ];
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarInstitute />
        {!loading && user && user.institute ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <InstituteSteps activeProfile={"BATCH"} />
                      <div className="pt-4">
                        <h4>We are racing towards the finish line...</h4>
                        <p>Tell us about your below preferences</p>
                        <p>Which mode of teaching you prefer?</p>
                      </div>
                      {/* <AddForm
                      edit={false}
                      submitForm={submitFormClicked}
                      inputFields={inputFields}
                      initialValues={initialValues}
                      hideBackBtn={true}
                    ></AddForm> */}
                      <Formik
                        initialValues={{
                          batch: user.institute.batch
                            ? user.institute.batch
                            : {
                                batch_timing: [],
                                days: [],
                                teaching_medium: [""],
                                student_per_batch: "",
                                nearest_railway_station: "",
                                distance_from_nearest_railway_station: "",
                                nearest_bus_stop: "",
                                distance_from_nearest_bus_stop: "",
                                have_projector: false,
                                class_air_conditioned: false,
                                have_lift: false,
                                have_2_wheeler_parking: false,
                                have_4_wheeler_parking: false,
                              },
                        }}
                        validationSchema={Yup.object({
                          // name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          submitFormClicked(values);
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row mx-auto">
                                <div className="row">
                                  <div className="col-md-6">
                                    <SelectBox
                                      label="PREFFERED TEACHING TIMINGS? (MULTISELECT)"
                                      name="batch.batch_timing"
                                      multiple
                                      placeholder="Enter PREFFERED TEACHING TIMINGS? (MULTISELECT)"
                                    >
                                      <option value="">
                                        {" "}
                                        Select PREFFERED TEACHING TIMINGS?
                                        (MULTISELECT){" "}
                                      </option>
                                      <option value="Early Morning">
                                        Early Morning
                                      </option>
                                      <option value="Morning"> Morning</option>
                                      <option value="Afternoon">
                                        {" "}
                                        Afternoon
                                      </option>
                                      <option value="Evening"> Evening</option>
                                    </SelectBox>
                                  </div>
                                  <div className="col-md-6">
                                    <SelectBox
                                      label="Which days you prefer to teach? (Multiselect)"
                                      name="batch.days"
                                      multiple
                                      placeholder="Enter Which days you prefer to teach? (Multiselect)"
                                    >
                                      <option value="">
                                        {" "}
                                        Select Which days you prefer to teach?
                                        (Multiselect){" "}
                                      </option>
                                      <option value="Monday">Monday</option>
                                      <option value="Tuesday">Tuesday</option>
                                      <option value="Wednesday">
                                        Wednesday
                                      </option>
                                      <option value="Thursday">Thursday</option>
                                      <option value="Friday">Friday</option>
                                      <option value="Saturday">Saturday</option>
                                      <option value="Sunday">Sunday</option>
                                    </SelectBox>
                                  </div>
                                  <div className="col-md-6">
                                    <SelectBox
                                      label="TEACHING MEDIUM (MULTISELECT):"
                                      name="batch.teaching_medium"
                                      multiple
                                      placeholder="Enter Which days you prefer to teach? (Multiselect)"
                                    >
                                      <option value="">
                                        {" "}
                                        Select Which days you prefer to teach?
                                        (Multiselect){" "}
                                      </option>
                                      {teachingMeadium &&
                                        teachingMeadium.map((item) => {
                                          return (
                                            <option value={item.value}>
                                              {" "}
                                              {item.label}{" "}
                                            </option>
                                          );
                                        })}
                                    </SelectBox>
                                  </div>
                                  <div className="col-md-6">
                                    <TextInput
                                      label="Student Per Batch"
                                      name="batch.student_per_batch"
                                      type="text"
                                      placeholder="Enter Student Per Batch"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <p>Infrastructure Details </p>
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Nearest Railway Station"
                                    name="batch.nearest_railway_station"
                                    type="text"
                                    placeholder="Enter Nearest Railway Station"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Distance from Nearest Railway Station"
                                    name="batch.distance_from_nearest_railway_station"
                                    type="text"
                                    placeholder="Enter Distance from Nearest Railway Station"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Nearest Bus Stop"
                                    name="batch.nearest_bus_stop"
                                    type="text"
                                    placeholder="Enter Nearest Bus Stop"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Distance From Nearest Bus Stop"
                                    name="batch.distance_from_nearest_bus_stop"
                                    type="text"
                                    placeholder="Enter Distance From Nearest Bus Stop"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <CheckBox name="batch.have_projector">
                                    Do you have a projector or digital classroom
                                    ?
                                  </CheckBox>
                                </div>
                                <div className="col-md-12">
                                  <CheckBox name="batch.class_air_conditioned">
                                    Is your class air-conditioned ?
                                  </CheckBox>
                                </div>
                                <div className="col-md-12">
                                  <CheckBox name="batch.have_lift">
                                    Do you have a lift/escalator in your
                                    building?
                                  </CheckBox>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <CheckBox name="batch.have_2_wheeler_parking">
                                    Is 2-wheeler parking available?
                                  </CheckBox>
                                </div>
                                <div className="col-md-12">
                                  <CheckBox name="batch.have_4_wheeler_parking">
                                    Is 4-wheeler parking available?
                                  </CheckBox>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "EDIT"}
                                  </button>
                                  <Link
                                    to={"/tutor/subjects"}
                                    className="btn btn-secondary m-1"
                                  >
                                    Next <i className="fa fa-angle-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default InstituteBatchDetails;
