import React from "react";
import { Link } from "react-router-dom";
import Icon11 from "../../assets/images/icon-11.png";
import Icon12 from "../../assets/images/icon-12.png";
import Icon13 from "../../assets/images/icon-13.png";
import Icon14 from "../../assets/images/icon-14.png";
import Icon15 from "../../assets/images/icon-15.png";
import Icon16 from "../../assets/images/icon-16.png";
import Icon17 from "../../assets/images/icon-17.png";
import Icon18 from "../../assets/images/icon-18.png";
import Icon19 from "../../assets/images/icon-19.png";
import Icon20 from "../../assets/images/icon-20.png";
import Icon21 from "../../assets/images/icon-21.png";
import Icon22 from "../../assets/images/icon-22.png";
import { URI } from "../../domain/constant";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";

function HomeCategories() {
  const [data] = useSelectAllCategory();
  const { loading, all_categorys } = data;

  return (
    <div className="section section-padding feature-category-section">
      <div className="container">
        {/* Feature Category Header Start */}
        <div className="feature-category-header">
          {/* Section Title Start */}
          <div className="section-title">
            <h2 className="title">Discover Tutors from Popular Categories</h2>
          </div>
          {/* Section Title End */}
          {/* Feature Category Start */}
          <div className="feature-category-btn">
            <a href="#" className="btn btn-outline-primary">
              Explore More
            </a>
          </div>
          {/* Feature Category End */}
        </div>
        {/* Feature Category Header End */}
        {/* Feature Category Body Start */}
        <div className="feature-category-body">
          <div className="row">
            {all_categorys &&
              all_categorys.map((item) => {
                return (
                  <div className="col-lg-3 col-sm-6">
                    {/* Category Item Start */}
                    <div className="single-category-item">
                      <Link to={`/tutors?category=${item.name}`}>
                        <img
                          className="item-icon"
                          src={`${URI}${item.image}`}
                          alt
                        />
                        <span className="title">{item.name}</span>
                      </Link>
                    </div>
                    {/* Category Item End */}
                  </div>
                );
              })}

            <div className="col-lg-3 col-sm-6">
              {/* Category Item Start */}
              <div className="single-category-item">
                <Link to="/tutors">
                  <img className="item-icon" src={Icon22} alt />
                  <span className="title">View ALL</span>
                </Link>
              </div>
              {/* Category Item End */}
            </div>
          </div>
        </div>
        {/* Feature Category Body End */}
      </div>
    </div>
  );
}

export default HomeCategories;
