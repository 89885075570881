import React, { useEffect } from "react";
import "./assets/css/plugins/font-awesome.min.css";
import "./assets/css/plugins/flaticon.css";
import "./assets/css/plugins/bootstrap.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/css/plugins/swiper-bundle.min.css";
import "./assets/css/plugins/nice-select2.css";
import "./assets/css/plugins/jquery.powertip.min.css";
import "./assets/css/plugins/glightbox.min.css";
import "./assets/css/style.min.css";
import "./assets/css/main.css";
import "./assets/css/custom-styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Home from "./containers/home/Home";
import setAuthToken from "./domain/setAuthToken";
import { loadUser } from "./store/actions/auth";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";
import ListTutors from "./containers/tutors/ListTutors";
import TutorDetails from "./containers/tutors/TutorDetails";
import Login from "./containers/login/Login";
import MyProfile from "./containers/account/student/MyProfile";
import MyReviews from "./containers/account/student/MyReviews";
import MyTutors from "./containers/account/student/MyTutors";
import UnlockedProfile from "./containers/account/student/UnlockedProfile";
import PaymentHistory from "./containers/account/student/PaymentHistory";
import TutorProfile from "./containers/account/tutor/TutorProfile";
import Register from "./containers/login/Register";
import ForgetPassword from "./containers/login/ForgetPassword";
import TutorQualifications from "./containers/account/tutor/TutorQualification";
import TutorSubjects from "./containers/account/tutor/TutorSubjects";
import TutorPreferences from "./containers/account/tutor/TutorPreferences";
import InstituteBatchDetails from "./containers/account/institute/InstituteBatchDetails";
import InstituteSubjects from "./containers/account/institute/InstituteSubjects";
import InstituteAbout from "./containers/account/institute/InstituteAbout";
import InstituteProfile from "./containers/account/institute/InstituteProfile";

function App() {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setAuthToken(localStorage.getItem("token"));
    }

    store.dispatch(loadUser());

    window.addEventListener("storage", () => {
      if (!localStorage.getItem("token")) store.dispatch({ type: "LOGOUT" });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route component={ListTutors} exact path={"/tutors"} />
          <Route component={TutorDetails} exact path={"/tutors/:id"} />
          <BeforeLoginRoutes component={Login} exact path={"/login"} />
          <BeforeLoginRoutes component={Register} exact path={"/register"} />
          <Route component={ForgetPassword} exact path={"/forget-password"} />
          <PrivateRoutes
            component={MyProfile}
            exact
            path={"/student/profile"}
          />
          <PrivateRoutes
            component={MyReviews}
            exact
            path={"/student/reviews"}
          />
          <PrivateRoutes
            component={MyTutors}
            exact
            path={"/student/saved-profiles"}
          />
          <PrivateRoutes
            component={UnlockedProfile}
            exact
            path={"/student/unlocked-profiles"}
          />
          <PrivateRoutes
            component={PaymentHistory}
            exact
            path={"/student/payment-history"}
          />
          <PrivateRoutes
            component={TutorProfile}
            exact
            path={"/tutor/profile"}
          />
          <PrivateRoutes
            component={TutorQualifications}
            exact
            path={"/tutor/qualifications"}
          />
          <PrivateRoutes
            component={TutorSubjects}
            exact
            path={"/tutor/subjects"}
          />
          <PrivateRoutes
            component={TutorPreferences}
            exact
            path={"/tutor/preferences"}
          />
          <PrivateRoutes
            component={InstituteProfile}
            exact
            path={"/institute/profile"}
          />

          <PrivateRoutes
            component={InstituteSubjects}
            exact
            path={"/institute/subjects"}
          />
          <PrivateRoutes
            component={InstituteBatchDetails}
            exact
            path={"/institute/batch"}
          />
          <PrivateRoutes
            component={InstituteAbout}
            exact
            path={"/institute/about"}
          />

          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
