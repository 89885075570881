import {
  SEARCH_BY_PIN,
  GET_STATE,
  GET_CITY,
  GET_AREA,
  GET_PINCODE,
  RESET_PINCODE,
} from "../types/pincode_type";
const initialState = {
  states: null,
  city: null,
  area: null,
  pincode: null,
  by_pincode: null,
  loading: true,
};

export const pincode_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATE:
      return {
        ...state,
        states: payload,
        loading: false,
      };
    case GET_CITY:
      return {
        ...state,
        city: payload,
        loading: false,
      };
    case GET_AREA:
      return {
        ...state,
        area: payload,
        loading: false,
      };
    case GET_PINCODE:
      return {
        ...state,
        pincode: payload,
        loading: false,
      };
    case SEARCH_BY_PIN:
      return {
        ...state,
        by_pincode: payload,
        loading: false,
      };
    case RESET_PINCODE:
      return {
        ...state,
        states: null,
        city: null,
        area: null,
        pincode: null,
        by_pincode: null,
        loading: true,
      };

    default:
      return state;
  }
};
