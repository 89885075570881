import { combineReducers } from "redux";
import { alert } from "./alert_reducer";
import { authReducer } from "./auth_reducer";
import { user_reducer } from "./user_reducer";
import { pincode_reducer } from "./pincode_reducer";
import { tutor_reducer } from "./tutor_reducer";
import { category_reducer } from "./category_reducer";
import { subject_reducer } from "./subject_reducer";
import { board_reducer } from "./board_reducer";

export default combineReducers({
  alert,
  auth: authReducer,
  user: user_reducer,
  pincode: pincode_reducer,
  tutor: tutor_reducer,
  category: category_reducer,
  subject: subject_reducer,
  board: board_reducer,
});
