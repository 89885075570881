import React from "react";
import DefaultMale from "../../assets/images/default-male.png";
import DefaultFemale from "../../assets/images/default-female.png";
import { Link } from "react-router-dom";
function TutorGrid({ tutor }) {
  return (
    <>
      {tutor && (
        <div className="single-course">
          <div className="courses-image">
            <a href="course-details.html">
              <img src={DefaultMale} alt="Courses" />
            </a>
          </div>
          <div className="courses-content">
            <div className="top-meta">
              <a className="tag" href="#">
                ₹500
              </a>
              <span className="price">
                <span className="sale-price">{}</span>
              </span>
            </div>
            <h3 className="title">
              <a href="course-details.html">
                {tutor.first_name} {tutor.last_name}
              </a>
            </h3>

            <p className="author-name">
              <i className="fa fa-map-marker"></i> Baishnab Ghata Patuli
              Township Kolkata West Bengal - 700094
            </p>
            <div className="">
              {tutor.tutor &&
                tutor.tutor.teaching_preferences &&
                tutor.tutor.teaching_preferences.home_tuition && (
                  <p>
                    {" "}
                    <i className="fa fa-home"></i> Home Tuition
                  </p>
                )}
              {tutor.tutor &&
                tutor.tutor.teaching_preferences &&
                tutor.tutor.teaching_preferences.group_tuition && (
                  <p>
                    {" "}
                    <i className="fa fa-users"></i> Group Tuition
                  </p>
                )}
              {tutor.tutor &&
                tutor.tutor.teaching_preferences &&
                tutor.tutor.teaching_preferences.online_tuition && (
                  <p>
                    {" "}
                    <i className="fa fa-laptop"></i> Online Tuition
                  </p>
                )}

              <p>
                Bharatnatyam dancer, performer and teacher for last 7 years.
                Extremely good at teaching spoken languages.
              </p>
            </div>
          </div>
          <div className="courses-meta">
            <div className="rating">
              <div className="rating-star">
                <div className="rating-active" style={{ width: "60%" }} />
              </div>
              <span>(4.5)</span>
            </div>
          </div>
          <div className="d-flex justify-content-between p-3">
            <div>
              <Link to={`/tutors/${tutor._id}`} className="btn btn-secondary">
                View Profile
              </Link>
            </div>
            <div>
              <Link to="/" className="btn btn-primary">
                Contact
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TutorGrid;
