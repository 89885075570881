import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addSubject,
  getSubjects,
  getSubject,
  editSubject,
  deleteSubject,
  getSubjectByCategory,
} from "../../store/actions/subject_action";
import { SEARCH_TERMS } from "../enums/subjects_enum";
// Get All Data

// Get Single Data
export const useSingleSubject = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.subject);
  useEffect(() => {
    dispatch(getSubject(id));
  }, [id]);
  return [data];
};
export const useGetSubjectByCategory = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(null);
  const data = useSelector((state) => state.subject);
  useEffect(() => {
    if (category) {
      dispatch(getSubjectByCategory(category));
    }
  }, [category]);
  return [category, setCategory, data];
};
// Add Data
export const useCreateSubject = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addSubject(data));
  };
  return [addData];
};
export const useUpdateSubject = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editSubject(id, data));
  };
  return [updateData];
};
