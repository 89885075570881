import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import { inputFields, initialValues } from "../../../shared/enums/address_enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
import InstituteSteps from "../../../components/common/InstituteSteps";
import SidebarInstitute from "../../../components/template/SidebarInstitute";
import { useAllStates } from "../../../shared/hooks/UsePincodes";
function InstituteProfile() {
  const dispatch = useDispatch();
  const [states] = useAllStates();
  const { user, loading } = useSelector((state) => state.auth);
  const submitFormClicked = async (values) => {
    await dispatch(updateProfile({ institute: { address: values } }));
  };

  const [yearsArray, setYearsArray] = useState(null);

  useEffect(() => {
    let years = [];
    for (let i = 1960; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    setYearsArray(years);
  }, []);
  // console.log(yearsArray);
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarInstitute />
        {!loading && user && user.institute ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <InstituteSteps activeProfile={"PROFILE"} />
                      <div className="pt-4">
                        <h4>Now something about your Institute...</h4>
                        <p>
                          We are going to ask you some question, for better
                          visibility of your profile.
                        </p>
                        <p>
                          You will need to upload your profile picture and
                          resume (optional). So make sure you have those handy
                          before we start.
                        </p>
                      </div>
                      {/* <AddForm
                        data={
                          user && user.tutor && user.tutor.address
                            ? user.tutor.address
                            : null
                        }
                        edit={true}
                        submitForm={submitFormClicked}
                        inputFields={inputFields}
                        initialValues={initialValues}
                        hideBackBtn={true}
                      ></AddForm> */}

                      <Formik
                        initialValues={{
                          established_year:
                            user.institute.address &&
                            user.institute.address.established_year
                              ? user.institute.address.established_year
                              : "",
                          institute_website:
                            user.institute.address &&
                            user.institute.address.institute_website
                              ? user.institute.address.institute_website
                              : "",
                          address:
                            user.institute.address &&
                            user.institute.address.address
                              ? user.institute.address.address
                              : "",
                          pin:
                            user.institute.address && user.institute.address.pin
                              ? user.institute.address.pin
                              : "",
                          area:
                            user.institute.address &&
                            user.institute.address.area
                              ? user.institute.address.area
                              : "",
                          city:
                            user.institute.address &&
                            user.institute.address.city
                              ? user.institute.address.city
                              : "",
                          state:
                            user.institute.address &&
                            user.institute.address.state
                              ? user.institute.address.state
                              : "",
                        }}
                        validationSchema={Yup.object({
                          established_year: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          submitFormClicked(values);
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <SelectBox
                                    label="established year"
                                    name="established_year"
                                    placeholder="Enter established year"
                                  >
                                    <option value="">
                                      {" "}
                                      Select established year{" "}
                                    </option>
                                    {yearsArray &&
                                      yearsArray.map((item) => {
                                        return (
                                          <option value={item}> {item} </option>
                                        );
                                      })}
                                  </SelectBox>
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Institute Website"
                                    name="institute_website"
                                    type="text"
                                    placeholder="Enter Institute Website"
                                  />
                                </div>
                                <div className="col-md-12 ">
                                  <TextInput
                                    label="What's Your Address:"
                                    name="address"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Whats's Your Pincode:"
                                    name="pin"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Area"
                                    name="area"
                                    type="text"
                                    placeholder="Enter Area"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="City"
                                    name="city"
                                    type="text"
                                    placeholder="Enter City"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                    label="State"
                                    name="state"
                                    placeholder="Enter State"
                                  >
                                    <option value=""> Select State </option>
                                    {states &&
                                      states.map((item) => {
                                        return (
                                          <option value={item}> {item} </option>
                                        );
                                      })}
                                  </SelectBox>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "EDIT"}
                                  </button>
                                  <Link
                                    to={"/tutor/subjects"}
                                    className="btn btn-secondary m-1"
                                  >
                                    Next <i className="fa fa-angle-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default InstituteProfile;
