import React from "react";
import Auther3 from "../../assets/images/author-3.jpg";
import Auther2 from "../../assets/images/author-2.jpg";
import Auther1 from "../../assets/images/author-1.jpg";
function HomeTestimonials() {
  return (
    <div className="section">
      <div className="container">
        {/* Testimonial Wrapper Start */}
        <div className="testimonial-wrapper">
          {/* Testimonial Author Start */}
          <div className="testimonial-author">
            {/* Testimonial Author Wrapper Start */}
            <div className="testimonial-author-wrapper">
              <div className="testimonial-quote">
                <i className="flaticon-left-quotes-sign" />
              </div>
              <div className="author-images-wrapper author-images-active">
                <div className="swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-thumbs">
                  <div
                    className="swiper-wrapper"
                    id="swiper-wrapper-15075622d878ec88"
                    aria-live="polite"
                    style={{ transitionDuration: "0ms" }}
                  >
                    <div
                      className="swiper-slide author-image swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev"
                      data-swiper-slide-index={2}
                      role="group"
                      aria-label="1 / 5"
                      style={{
                        width: 254,
                        opacity: 1,
                        transform: "translate3d(0px, 0px, 0px)",
                        transitionDuration: "0ms",
                      }}
                    >
                      <img src={Auther3} alt="Author" />
                    </div>
                    <div
                      className="swiper-slide author-image swiper-slide-duplicate-active swiper-slide-thumb-active"
                      data-swiper-slide-index={0}
                      role="group"
                      aria-label="2 / 5"
                      style={{
                        width: 254,
                        opacity: 1,
                        transform: "translate3d(-254px, 0px, 0px)",
                        transitionDuration: "0ms",
                      }}
                    >
                      <img src={Auther1} alt="Author" />
                    </div>
                    <div
                      className="swiper-slide author-image"
                      data-swiper-slide-index={1}
                      role="group"
                      aria-label="3 / 5"
                      style={{
                        width: 254,
                        opacity: 1,
                        transform: "translate3d(-508px, 0px, 0px)",
                        transitionDuration: "0ms",
                      }}
                    >
                      <img src="assets/images/author-1.jpg" alt="Author" />
                    </div>
                    <div
                      className="swiper-slide author-image swiper-slide-prev swiper-slide-duplicate-next"
                      data-swiper-slide-index={2}
                      role="group"
                      aria-label="4 / 5"
                      style={{
                        width: 254,
                        opacity: 1,
                        transform: "translate3d(-762px, 0px, 0px)",
                        transitionDuration: "0ms",
                      }}
                    >
                      <img src={Auther1} alt="Author" />
                    </div>
                    <div
                      className="swiper-slide author-image swiper-slide-duplicate swiper-slide-visible swiper-slide-active swiper-slide-thumb-active"
                      data-swiper-slide-index={0}
                      role="group"
                      aria-label="5 / 5"
                      style={{
                        width: 254,
                        opacity: 1,
                        transform: "translate3d(-1016px, 0px, 0px)",
                        transitionDuration: "0ms",
                      }}
                    >
                      <img src={Auther1} alt="Author" />
                    </div>
                  </div>
                  <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  />
                </div>
              </div>
            </div>
            {/* Testimonial Author Wrapper End */}
          </div>
          {/* Testimonial Author End */}
          {/* Testimonial Content Start */}
          <div className="testimonial-content testimonial-content-active">
            <div className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
              <div
                className="swiper-wrapper"
                id="swiper-wrapper-2ce109f8a46bbf109d"
                aria-live="off"
                style={{
                  transitionDuration: "0ms",
                  transform: "translate3d(-2604px, 0px, 0px)",
                }}
              >
                <div
                  className="swiper-slide single-testimonial-content swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev"
                  data-swiper-slide-index={2}
                  role="group"
                  aria-label="1 / 5"
                  style={{ width: 631, marginRight: 20 }}
                >
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sequi ipsam dolorum deleniti officiis quasi repellendus
                    exercitationem a impedit. Sapiente possimus aliquam ut
                    laborum inventore perspiciatis beatae consequuntur eius, cum
                    ipsum!
                  </p>
                  <h5 className="name">Webgrow Solution</h5>
                  <span className="designation">IT Company</span>
                </div>
                <div
                  className="swiper-slide single-testimonial-content swiper-slide-duplicate-active"
                  data-swiper-slide-index={0}
                  role="group"
                  aria-label="2 / 5"
                  style={{ width: 631, marginRight: 20 }}
                >
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sequi ipsam dolorum deleniti officiis quasi repellendus
                    exercitationem a impedit. Sapiente possimus aliquam ut
                    laborum inventore perspiciatis beatae consequuntur eius, cum
                    ipsum!
                  </p>
                  <h5 className="name">Webgrow Solution</h5>
                  <span className="designation">IT Company</span>
                </div>
                <div
                  className="swiper-slide single-testimonial-content"
                  data-swiper-slide-index={1}
                  role="group"
                  aria-label="3 / 5"
                  style={{ width: 631, marginRight: 20 }}
                >
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sequi ipsam dolorum deleniti officiis quasi repellendus
                    exercitationem a impedit. Sapiente possimus aliquam ut
                    laborum inventore perspiciatis beatae consequuntur eius, cum
                    ipsum!
                  </p>
                  <h5 className="name">Webgrow Solution</h5>
                  <span className="designation">IT Company</span>
                </div>
                <div
                  className="swiper-slide single-testimonial-content swiper-slide-prev swiper-slide-duplicate-next"
                  data-swiper-slide-index={2}
                  role="group"
                  aria-label="4 / 5"
                  style={{ width: 631, marginRight: 20 }}
                >
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sequi ipsam dolorum deleniti officiis quasi repellendus
                    exercitationem a impedit. Sapiente possimus aliquam ut
                    laborum inventore perspiciatis beatae consequuntur eius, cum
                    ipsum!
                  </p>
                  <h5 className="name">Webgrow Solution</h5>
                  <span className="designation">IT Company</span>
                </div>
                <div
                  className="swiper-slide single-testimonial-content swiper-slide-duplicate swiper-slide-active"
                  data-swiper-slide-index={0}
                  role="group"
                  aria-label="5 / 5"
                  style={{ width: 631, marginRight: 20 }}
                >
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sequi ipsam dolorum deleniti officiis quasi repellendus
                    exercitationem a impedit. Sapiente possimus aliquam ut
                    laborum inventore perspiciatis beatae consequuntur eius, cum
                    ipsum!
                  </p>
                  <h5 className="name">Webgrow Solution</h5>
                  <span className="designation">IT Company</span>
                </div>
              </div>
              <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                <span
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                  tabIndex={0}
                  role="button"
                  aria-label="Go to slide 1"
                />
                <span
                  className="swiper-pagination-bullet"
                  tabIndex={0}
                  role="button"
                  aria-label="Go to slide 2"
                />
                <span
                  className="swiper-pagination-bullet"
                  tabIndex={0}
                  role="button"
                  aria-label="Go to slide 3"
                />
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
            </div>
          </div>
          {/* Testimonial Content End */}
        </div>
        {/* Testimonial Wrapper End */}
      </div>
    </div>
  );
}

export default HomeTestimonials;
