import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../store/actions/auth";
import { useUpdateTutor } from "../../shared/hooks/UseTutor";
import moment from "moment";
function ReviewForm({ tutor }) {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const data = useSelector((state) => state.auth);
  const [addReview] = useUpdateTutor();
  const [showRating, setShowRating] = useState(true);
  const { isAuthenticated, user } = data;
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    // other logic
  };
  const submitReview = () => {
    const formData = {
      rating: rating / 20,
      review: review,
    };
    addReview(tutor._id, formData);
  };
  useEffect(() => {
    if (tutor.reviews && user) {
      const filterData = tutor.reviews.filter(
        (item) => item.student && item.student._id == user._id
      );
      if (filterData && filterData.length > 0) {
        setShowRating(false);
      }
    }
  }, [tutor.reviews, user]);
  return (
    <div>
      <div className="add-review">
        <h3>
          {" "}
          Student Reviews for {tutor.first_name} {tutor.last_name}
        </h3>
        <p>Only registered students can submit review.</p>
      </div>
      {tutor.reviews &&
        tutor.reviews.map((item) => {
          return (
            <div className="card m-1 p-3">
              <div class="comment-content">
                <div class="reviews">
                  <p class="meta">
                    <strong>Nishant Tripathi </strong>(verified User) –
                    {moment(item.createdAt).format("DD-MMM-YYYY")}
                  </p>
                  <div class="rating">
                    <Rating
                      ratingValue={item.rating * 20}
                      readonly={true}
                      size={20}
                    />
                  </div>
                </div>
                <p>{item.review}</p>
              </div>
            </div>
          );
        })}
      {showRating && (
        <div className="add-review">
          <h3> Your rating</h3>
          <div className="star-fill">
            <Rating
              onClick={handleRating}
              ratingValue={rating} /* Available Props */
              showTooltip={true}
            />
            <div className="write-review">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">
                  Your review *
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  defaultValue={""}
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </div>
              {isAuthenticated && user && user.user_type == "Student" ? (
                <div className>
                  <button
                    className="btn btn-profile bg-f"
                    onClick={() => submitReview()}
                  >
                    Submit{" "}
                  </button>
                </div>
              ) : (
                <p> Login as student give rating </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewForm;
