import moment from "moment";

export const PAGE_TITLE = "Categories";
export const PAGE_SINGLE_TITLE = "Category";
export const LINK_URL = "categories";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    slug: true,
    inputType: "text",
  },
  content: {
    type: "html",
    required: false,
    title: "Content",
  },
  category_image: {
    type: "file",
    required: false,
    title: "Category Image",
  },
  banners: {
    type: "gallery",
    required: false,
    title: "Banners",
  },
  parent_category: {
    type: "select",
    required: false,
    title: "Parent Category",
  },
  show_on_header: {
    type: "checkbox",
    required: false,
    title: "Show on Header",
  },
  published_date: {
    type: "string",
    required: true,
    title: "Published Date",
    inputType: "date",
  },

  meta_title: {
    type: "string",
    required: false,
    title: "Meta Title",
  },
  meta_description: {
    type: "text",
    required: false,
    title: "Meta Description",
  },
  is_red_color: {
    type: "checkbox",
    required: false,
    title: "Red Color",
  },
  slug: {
    type: "slug",
    required: false,
    targetField: "name",
    title: "Slug",
  },
};
export const initialValues = {
  name: "",
  content: "",
  parent_category: "",
  show_on_header: false,
  published_date: moment().format("YYYY-MM-DD"),
  meta_title: "",
  meta_description: "",
  slug: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Slug", value: "slug" },
];
