import React from "react";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarStudent from "../../../components/template/SidebarStudent";

function MyTutors() {
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarStudent />
        <div className="main-profile-content">
          <div className="container-fluid">
            <div className="row" id="display_profile">
              <div className="col-12 col-lg-4 col-xl-4">
                <div className="card gradient-rainbow border-0">
                  <div className="card-body">
                    <img
                      src="../file/20220225111543.png"
                      style={{ width: "100%", height: "auto" }}
                      className="rounded img-fluid"
                      alt="Amitkumar Singh"
                    />
                    <div className="media mt-4">
                      <div className="media-body">
                        <h3 className="mb-0 ">Amitkumar Singh</h3>
                      </div>
                      <i className="zmdi zmdi-bookmark fa-2x " />
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <h5 className="">Subject</h5>
                        <p className="">
                          English,Hindi,Entrepreneurship,Physical Education
                          (P.E.),Business Studies,Economics,Spoken
                          English,Arts,Chess Coach,Cricket Coach,Tennis
                          Coach,History,Geography
                        </p>
                        <h5 className="">Tuition Fee</h5>
                        <p className="">₹ 2000</p>
                      </div>
                      <div className="col-md-6 text-center">
                        <span className="zmdi zmdi-star-outline fa-lg " />
                        <span className="zmdi zmdi-star-outline fa-lg " />
                        <span className="zmdi zmdi-star-outline fa-lg " />
                        <span className="zmdi zmdi-star-outline fa-lg " />
                        <span className="zmdi zmdi-star-outline fa-lg " />
                        <span className="">0</span>
                        <h5 className=" mt-3 ">Experience</h5>
                        <p className="">5 Years</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer align-items-center bg-transparent border-light-2">
                    <div className="row">
                      <div className="col">
                        <a
                          href="../21/amitkumar.singh"
                          target="_blank"
                          className=" btn btn-inverse-warning m-1"
                        >
                          Visit Profile
                        </a>
                      </div>
                      <div className="col text-right">
                        <button
                          className=" btn btn-inverse-danger m-1 delete_profile"
                          data-id={11}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <div className="card gradient-rainbow border-0">
                  <div className="card-body">
                    <img
                      src="../file/20220305015725.png"
                      style={{ width: "100%", height: "auto" }}
                      className="rounded img-fluid"
                      alt="Nishant Tripathi"
                    />
                    <div className="media mt-4">
                      <div className="media-body">
                        <h3 className="mb-0 ">Nishant Tripathi</h3>
                      </div>
                      <i className="zmdi zmdi-bookmark fa-2x " />
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <h5 className="">Subject</h5>
                        <p className="">Spoken English,English,Hindi</p>
                        <h5 className="">Tuition Fee</h5>
                        <p className="">₹ 200</p>
                      </div>
                      <div className="col-md-6 text-center">
                        <span className="zmdi zmdi-star fa-lg " />
                        <span className="zmdi zmdi-star fa-lg " />
                        <span className="zmdi zmdi-star fa-lg " />
                        <span className="zmdi zmdi-star fa-lg " />
                        <span className="zmdi zmdi-star fa-lg " />
                        <span className="">5</span>
                        <h5 className=" mt-3 ">Experience</h5>
                        <p className="">2 Years</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer align-items-center bg-transparent border-light-2">
                    <div className="row">
                      <div className="col">
                        <a
                          href="../21/nishant.tripathi"
                          target="_blank"
                          className=" btn btn-inverse-warning m-1"
                        >
                          Visit Profile
                        </a>
                      </div>
                      <div className="col text-right">
                        <button
                          className=" btn btn-inverse-danger m-1 delete_profile"
                          data-id={10}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
            {/*start overlay*/}
            <div className="overlay toggle-menu" />
            {/*end overlay*/}
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyTutors;
