import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddForm from "../../../components/common/AddForm";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarTutor from "../../../components/template/SidebarTutor";
import {
  inputFields,
  initialValues,
} from "../../../shared/enums/teaching_qualification_enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import TutorSteps from "../../../components/common/TutorSteps";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
import InstituteSteps from "../../../components/common/InstituteSteps";
import SidebarInstitute from "../../../components/template/SidebarInstitute";
function InstituteAbout() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const submitFormClicked = async (values) => {
    await dispatch(updateProfile({ tutor: { profile: values } }));
  };
  const [featuredImage, setFeaturedImage] = useState(null);
  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarInstitute />
        {!loading && user && user.tutor ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <InstituteSteps activeProfile={"ABOUT"} />
                      <div className="pt-4">
                        <h4>One last thing...</h4>
                        <p>We won't take a lot of your time, we promise!</p>
                      </div>

                      <Formik
                        initialValues={{
                          name: "",
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);

                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-12">
                                  <TextArea
                                    label="CAN YOU DESCRIBE YOUR INSTITUTE IN ABOUT 2 LINES?(MAX 160 CHARACTERS)"
                                    name="short_description"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextArea
                                    label="YOU CAN DESCRIBE ABOUT YOUR INSTITUTE IN DETAIL HERE"
                                    name="description"
                                    type="text"
                                    placeholder="Enter YOU CAN DESCRIBE ABOUT YOUR INSTITUTE IN DETAIL HERE"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label> Upload Institute Logo </label>

                                  <input
                                    type="file"
                                    className="form-control-file"
                                    onChange={(e) =>
                                      setFeaturedImage(e.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    {" "}
                                    DO YOU HAVE A INSTITUTE BROCHURE?{" "}
                                  </label>

                                  <input
                                    type="file"
                                    className="form-control-file"
                                    onChange={(e) =>
                                      setFeaturedImage(e.target.files[0])
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Update"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default InstituteAbout;
