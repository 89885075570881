import React from "react";
import { Link } from "react-router-dom";

function InstituteSteps({ activeProfile }) {
  return (
    <div className="steps">
      <div
        className={
          activeProfile == "PROFILE" ? "step-item active-step" : "step-item "
        }
      >
        <Link to="/institute/profile"> Step 1 </Link>
      </div>
      <div
        className={
          activeProfile == "SUBJECT" ? "step-item active-step" : "step-item "
        }
      >
        <Link to="/institute/subjects"> Step 2 </Link>
      </div>
      <div
        className={
          activeProfile == "BATCH" ? "step-item active-step" : "step-item "
        }
      >
        <Link to="/institute/batch"> Step 3 </Link>
      </div>
      <div
        className={
          activeProfile == "ABOUT" ? "step-item active-step" : "step-item "
        }
      >
        <Link to="/institute/about"> Step 4 </Link>
      </div>
    </div>
  );
}

export default InstituteSteps;
