import React, { useEffect, useState } from "react";
import DefaultMale from "../../assets/images/default-male.png";
import DefaultFemale from "../../assets/images/default-female.png";
import { Link } from "react-router-dom";
import { URI } from "../../domain/constant";
import Graduate from "../../assets/images/magistrate.png";
import Subject from "../../assets/images/bookshelf.png";
import category from "../../assets/images/folder-management.png";
import { Rating } from "react-simple-star-rating";
function TutorCard({ tutor }) {
  const [averageRatings, setAverageRatings] = useState(0);
  useEffect(() => {
    if (tutor && tutor.reviews && tutor.reviews.length > 0) {
      let total = 0;
      const sum = tutor.reviews
        .map((item) => item.rating)
        .reduce((prev, next) => prev + next);
      setAverageRatings((sum * 20) / tutor.reviews.length);
    }
  }, [tutor]);
  if (tutor && tutor.tutor) {
    return (
      <div>
        <div className="single-list">
          <div className="row">
            <div className="col-md-3">
              <div className="tutor-image">
                {tutor.tutor.profile && tutor.tutor.profile.profile_image ? (
                  <img
                    src={`${URI}${tutor.tutor.profile.profile_image}`}
                    alt={"Tutor"}
                  />
                ) : (
                  <img src={DefaultMale} alt="Tutor" />
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="main-holder">
                <div className="single-card-flex">
                  <div className="tutor-name">
                    <h3>
                      {tutor.first_name} {tutor.last_name}
                    </h3>
                  </div>
                  <div>
                    <span className="auto-style4" style={{ marginLeft: "1em" }}>
                      <i
                        className="fa fa-map-marker"
                        style={{
                          fontSize: 16,
                          marginRight: 10,
                          color: "#3bbca7",
                        }}
                      />

                      <span
                        style={{
                          color: "#000",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#888",
                        }}
                      >
                        {tutor.tutor.address &&
                          `${tutor.tutor.address.area} ${tutor.tutor.address.city} ${tutor.tutor.address.state} ${tutor.tutor.address.pin}`}
                      </span>
                    </span>
                  </div>
                  <div className="star-rating">
                    <Rating
                      ratingValue={averageRatings}
                      readonly={true}
                      size={20}
                    />
                  </div>
                </div>
                <div className="tution-flex">
                  {tutor.tutor.teaching_preferences &&
                    tutor.tutor.teaching_preferences.home_tuition && (
                      <div className="">
                        <span>
                          <i className="fa fa-home"></i> Home Tuition
                        </span>
                      </div>
                    )}
                  {tutor.tutor.teaching_preferences &&
                    tutor.tutor.teaching_preferences.group_tuition && (
                      <div className="">
                        <span>
                          <i className="fa fa-users"></i> Group Tuition
                        </span>
                      </div>
                    )}
                  {tutor.tutor.teaching_preferences &&
                    tutor.tutor.teaching_preferences.online_tuition && (
                      <div className="">
                        <span>
                          <i className="fa fa-laptop"></i> Online Tuition
                        </span>
                      </div>
                    )}
                </div>
                <div className="taught">
                  <p>
                    {tutor.tutor.profile && tutor.tutor.profile.short_profile
                      ? tutor.tutor.profile.short_profile
                      : ""}
                  </p>
                </div>
                <div className="experience-flex">
                  <div className="graduate-f">
                    <div>
                      <img src={Graduate} />
                    </div>
                    <div>
                      <h3>Experience</h3>
                      <h5>
                        {" "}
                        {tutor.tutor.teaching_profile &&
                        tutor.tutor.teaching_profile.total_teaching_experience
                          ? tutor.tutor.teaching_profile
                              .total_teaching_experience
                          : ""}{" "}
                        Years
                      </h5>
                    </div>
                  </div>
                  <div className="graduate-f">
                    <div>
                      <img src={Subject} />
                    </div>
                    <div>
                      <h3>Subjects</h3>
                      <h5>
                        {" "}
                        {tutor.tutor.subjects && tutor.tutor.subjects.subject
                          ? tutor.tutor.subjects.subject
                          : ""}{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="graduate-f">
                    <div>
                      <img src={category} />
                    </div>
                    <div>
                      <h3>Category</h3>
                      <h5>
                        {" "}
                        {tutor.tutor.subjects && tutor.tutor.subjects.category
                          ? tutor.tutor.subjects.category
                          : ""}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="rate-flex">
                  <div>
                    <Link
                      to={`/tutors/${tutor._id}`}
                      className="btn btn-profile"
                    >
                      View Profile
                    </Link>
                  </div>
                  <div className="">
                    <Link
                      to={`/tutors/${tutor._id}`}
                      className="btn btn-profile bg-f"
                    >
                      Contact{" "}
                    </Link>
                  </div>
                  <div className="rupees">
                    <h3>
                      ₹{" "}
                      {tutor.tutor.teaching_preferences &&
                      tutor.tutor.teaching_preferences.average_fee_per_hour
                        ? tutor.tutor.teaching_preferences.average_fee_per_hour
                        : ""}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default TutorCard;
