import React from "react";
import Shape11 from "../../assets/images/shape/shape-11.svg";
import Hero2 from "../../assets/images/hero-2.png";
import { Link } from "react-router-dom";
function HomeSlider() {
  return (
    <div className="slider-section section bg-color-2">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6">
            {/* Slider Start */}
            <div className="slider-content">
              <h5 className="sub-title">developed by top teachers</h5>
              <h2 className="title">
                Experience a learning platform that take you next level
              </h2>
              <p>
                World-class training and development programs developed by top
                teachers
              </p>
              <Link
                to="/tutors"
                className="btn btn-primary btn-hover-heading-color"
              >
                Explore All Tutors
              </Link>
            </div>
            {/* Slider End */}
          </div>
          <div className="col-md-6 col-sm-8">
            {/* Slider Images Start */}
            <div className="slider-images-02">
              <div className="image-shape-01 parallaxed">
                <img src={Shape11} alt="Shape" />
              </div>
              <div className="image-shape-02 parallaxed" />
              <div className="image-shape-03 parallaxed" />
              <div className="image">
                <img src={Hero2} alt="Hero" />
              </div>
            </div>
            {/* Slider Images End */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSlider;
