import React from "react";
import { Link } from "react-router-dom";

function SidebarStudent() {
  return (
    <div>
      <ul className="sidebar-menu in">
        <li className="sidebar-header">Student Dashboard</li>
        <li className="active">
          <Link to="/student/profile" className="waves-effect active">
            <i className="fa fa-tasks" /> <span>My Profile</span>
          </Link>
        </li>
        <li>
          <Link to="/student/reviews" className="waves-effect">
            <i className="fa fa-tasks" /> <span>My Reviews</span>
          </Link>
        </li>
        <li>
          <Link to="/student/saved-profiles" className="waves-effect">
            <i className="fa fa-tasks" /> <span>My Tutors</span>
          </Link>
        </li>
        <li>
          <Link to="/student/unlocked-profiles" className="waves-effect">
            <i className="fa fa-tasks" /> <span>Unlocked Profile</span>
          </Link>
        </li>
        <li>
          <Link to="/student/payment-history" className="waves-effect">
            <i className="fa fa-tasks" /> <span>Payment History</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default SidebarStudent;
