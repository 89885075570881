export const inputFields = {
  user_type: {
    type: "select",
    required: true,
    title: "You are registering for?",
    options: [
      { label: "Tutor", value: "Tutor" },
      { label: "Student", value: "Student" },
      { label: "Institute", value: "Institute" },
    ],
    col: 12,
  },
  first_name: {
    type: "string",
    required: true,
    title: "First Name",
    inputType: "text",
  },
  last_name: {
    type: "string",
    required: true,
    title: "Last Name",
    inputType: "text",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
    inputType: "text",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "text",
  },
  gender: {
    type: "select",
    required: true,
    title: "Gender",
    options: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
      { label: "Others", value: "Others" },
    ],
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
  },
};
export const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  user_type: "",
};
