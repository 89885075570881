import {
  GET_CATEGORYS,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY,
  RESET_CATEGORY,
  GET_ALL_CATEGORYS,
  ERROR_CATEGORY,
} from "../types/category_type";

const initialState = {
  categorys: null,
  page: null,
  pages: null,
  category: null,
  total_categorys: 0,
  loading: true,
  error: {},
  category_message: null,
  all_categorys: null,
};

export const category_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORYS:
      return {
        ...state,
        categorys: payload.categorys,
        pages: payload.pages,
        page: payload.page,
        total_categorys: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_CATEGORYS:
      return {
        ...state,
        all_categorys: payload,
        loading: false,
        error: {},
      };

    case RESET_CATEGORY:
      return {
        ...state,
        category: null,
        loading: true,
        error: {},
      };

    case ADD_CATEGORY:
      return {
        ...state,
        category_message: payload,
        loading: false,
        error: {},
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
        error: {},
      };
    case EDIT_CATEGORY:
      return {
        ...state,
        category_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CATEGORY:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
