import React from "react";
import Brand1 from "../../assets/images/brand/brand-1.png";
import Brand2 from "../../assets/images/brand/brand-2.png";
import Brand3 from "../../assets/images/brand/brand-3.png";
import Brand4 from "../../assets/images/brand/brand-4.png";
import Brand5 from "../../assets/images/brand/brand-5.png";
import Slider from "react-slick";
import { useSelectAllBoards } from "../../shared/hooks/UseBoard";
import { URI } from "../../domain/constant";
import { Link } from "react-router-dom";
function HomeBoards() {
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [board] = useSelectAllBoards();
  const { loading, all_boards } = board;
  return (
    <div className="section">
      <div className="container">
        {/* Brand Wrapper End */}
        <div className="brand-wrapper section-padding text-center border-0">
          <h3 className="brand-title">Choose Tutors from Popular Boards</h3>
          <div className="brand-active">
            <div>
              <div>
                {/* Single Brand Start */}
                <Slider {...settings}>
                  {all_boards &&
                    all_boards.map((item) => {
                      return (
                        <div className="single-brand">
                          <Link to={`tutors?board=${item.name}`}>
                            <img src={`${URI}${item.image}`} alt="Brand" />
                          </Link>
                        </div>
                      );
                    })}
                </Slider>
                {/* Single Brand End */}
              </div>
            </div>
          </div>
        </div>
        {/* Brand Wrapper End */}
      </div>
    </div>
  );
}

export default HomeBoards;
