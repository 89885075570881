import React from "react";
import { Link } from "react-router-dom";

function SidebarTutor({ id }) {
  return (
    <div>
      <ul className="sidebar-menu in">
        <li className="sidebar-header">Student Dashboard</li>
        <li className="active">
          <Link to="/tutor/profile" className="waves-effect active">
            <i className="fa fa-tasks" /> <span>My Profile</span>
          </Link>
        </li>
        <li>
          <Link to={`/tutors/${id}`} className="waves-effect">
            <i className="fa fa-tasks" /> <span>View Profile</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default SidebarTutor;
