import api from "../../domain/api";
import {
  GET_SUBJECTS,
  ADD_SUBJECT,
  EDIT_SUBJECT,
  GET_SUBJECT,
  RESET_SUBJECT,
  GET_ALL_SUBJECTS,
  ERROR_SUBJECT,
} from "../types/subject_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addSubject = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/subjects`, formData);
    dispatch({
      type: ADD_SUBJECT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getSubjects =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_SUBJECT,
      });
      const { data } = await api.get(
        `/subjects?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_SUBJECTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getSubject = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SUBJECT,
    });
    const { data } = await api.get(`/subjects/${id}`);

    dispatch({
      type: GET_SUBJECT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getSubjectByCategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SUBJECT,
    });
    const { data } = await api.get(`/subjects?exact[category]=${id}`);

    dispatch({
      type: GET_SUBJECTS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const editSubject = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/subjects/${id}`, formData);
    dispatch({
      type: EDIT_SUBJECT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteSubject = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/subjects/${id}`);
    dispatch(setAlert("Subject Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllSubjects = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/subjects/all`);

    dispatch({
      type: GET_ALL_SUBJECTS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_SUBJECT });
};
