import React, { useEffect, useState } from "react";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import { useSingleTutor } from "../../shared/hooks/UseTutor";
import DefaultMale from "../../assets/images/default-male.png";
import DefaultFemale from "../../assets/images/default-female.png";
import Shape21 from "../../assets/images/shape/shape-21.png";
import { URI } from "../../domain/constant";
import Rupess from "../../assets/images/rupee-indian.png";
import Location from "../../assets/images/placeholder.png";
import Link from "../../assets/images/link.png";
import Graduate from "../../assets/images/magistrate.png";
import Subject from "../../assets/images/bookshelf.png";
import category from "../../assets/images/folder-management.png";
import ReviewForm from "../../components/tutor/ReviewForm";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../store/actions/auth";
function TutorDetails({ match }) {
  const dispatch = useDispatch();
  const [data] = useSingleTutor(match.params.id);
  const { loading, tutor } = data;
  // console.log(user);
  const [averageRatings, setAverageRatings] = useState(0);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (tutor && tutor.reviews && tutor.reviews.length > 0) {
      let total = 0;
      const sum = tutor.reviews
        .map((item) => item.rating)
        .reduce((prev, next) => prev + next);
      setAverageRatings((sum * 20) / tutor.reviews.length);
    }
  }, [tutor]);
  const [isSavedProfile, setIsSavedProfile] = useState(false);
  useEffect(() => {
    if (user && user.user_type == "Student") {
      const saved_profile = user.student && user.student.saved_profile;

      if (saved_profile.length > 0) {
        console.log(saved_profile);
        const filteredData = saved_profile.filter(
          (item) => item.tutor && item.tutor._id == match.params.id
        );
        // console.log("FILTER TUTOR", filteredData, user._id);
        if (filteredData && filteredData.length > 0) {
          setIsSavedProfile(true);
        } else {
          setIsSavedProfile(false);
        }
      } else {
        setIsSavedProfile(false);
      }
    }
  }, [user]);

  const saveProfile = () => {
    if (user && user.user_type == "Student") {
      const saved_profile =
        user.student && user.student.saved_profile
          ? user.student.saved_profile
          : [];
      if (saved_profile.length > 0) {
        console.log(saved_profile);
        const filteredData = saved_profile.filter(
          (item) => item.tutor && item.tutor._id == match.params.id
        );
        // console.log("FILTER TUTOR", filteredData, user._id);
        if (filteredData && filteredData.length > 0) {
          const newData = saved_profile.filter(
            (item) => item.tutor && item.tutor._id !== match.params.id
          );
          const formData = {
            student: {
              saved_profile: newData,
            },
          };
          dispatch(updateProfile(formData));
        } else {
          const formData = {
            student: {
              saved_profile: [...saved_profile, { tutor: match.params.id }],
            },
          };
          dispatch(updateProfile(formData));
        }
      } else {
        const formData = {
          student: {
            saved_profile: [...saved_profile, { tutor: match.params.id }],
          },
        };
        dispatch(updateProfile(formData));
      }
    }
  };

  return (
    <div className="main-wrapper">
      <Header />
      {tutor && (
        <>
          {tutor.tutor ? (
            <>
              <section className="single-banner ">
                <div className="overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="banner-content">
                        <h3>
                          {tutor.tutor.subjects && tutor.tutor.subjects.subject
                            ? tutor.tutor.subjects.subject
                            : ""}{" "}
                        </h3>
                        <h2>
                          Tutor in{" "}
                          {tutor.tutor.address && tutor.tutor.address.city}
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ol className="breadcrumb d-flex align-items-center justify-content-md-end ml-auto">
                        <li className="breadcrumb-item">
                          <a href="#">
                            <i className="fa fa-home mr-1" />
                            Home
                          </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">
                            <i className="fa fa-chevron-right mr-1" />
                          </a>
                        </li>
                        <li className="breadcrumb-item active">
                          <span style={{ textTransform: "capitalize" }}>
                            {tutor.first_name} {tutor.last_name}
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="main-single-in ptb-50">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="single-tutor-information">
                        {tutor.tutor.profile &&
                        tutor.tutor.profile.profile_image ? (
                          <img
                            src={`${URI}${tutor.tutor.profile.profile_image}`}
                            alt={"Tutor"}
                          />
                        ) : (
                          <img src={DefaultMale} alt="Tutor" />
                        )}
                        <div className="information-single">
                          <div className="information-single-flex">
                            <div className="icon-single ic-f">
                              <img src={Rupess} />
                              <h3> Tuition Fee :</h3>
                            </div>
                            <div className="icon-single-value">
                              <h3>
                                {" "}
                                ₹
                                {tutor.tutor.teaching_preferences &&
                                tutor.tutor.teaching_preferences
                                  .average_fee_per_hour
                                  ? tutor.tutor.teaching_preferences
                                      .average_fee_per_hour
                                  : ""}{" "}
                                Per Hour
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="information-single">
                          <div className="information-single-flex">
                            <div className="icon-single ic-f">
                              <img src={Location} />
                              <h3> Address:</h3>
                            </div>
                            <div className="icon-single-value">
                              <h3>
                                {" "}
                                {tutor.tutor.address &&
                                  `${tutor.tutor.address.area} ${tutor.tutor.address.city} ${tutor.tutor.address.state} ${tutor.tutor.address.pin}`}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="information-single">
                          <div className="information-single-flex">
                            <div className="icon-single ic-f">
                              <img src={Link} />
                              <h3> Profile :</h3>
                            </div>
                            <div className="icon-single-value">
                              <h3 style={{ wordBreak: "break-word" }}>
                                {" "}
                                http://demoproextraclass.webgrowsolutions.com/tutors/
                                {tutor._id}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="information-single">
                          <div className="information-single-flex">
                            <div className="icon-single ic-f">
                              {isSavedProfile ? (
                                <button
                                  class="btn btn-profile"
                                  onClick={() => saveProfile()}
                                >
                                  <i className="fa fa-heart"></i> SAVED PROFILE
                                </button>
                              ) : (
                                <button
                                  class="btn btn-profile"
                                  onClick={() => saveProfile()}
                                >
                                  SAVE PROFILE
                                </button>
                              )}
                            </div>
                            <div className="icon-single-value">
                              <button class="btn btn-profile bg-f">
                                CONTACT NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="single-tutor-informationss">
                        <div className="main-holder">
                          <div className="single-card-flex">
                            <div className="tutor-name">
                              <h3>
                                {" "}
                                {tutor.first_name} {tutor.last_name}{" "}
                              </h3>
                            </div>
                            <div>
                              <span
                                className="auto-style4"
                                style={{ marginLeft: "1em" }}
                              >
                                <i
                                  className="fa fa-map-marker"
                                  style={{
                                    fontSize: 16,
                                    marginRight: 10,
                                    color: "rgb(59, 188, 167)",
                                  }}
                                />
                                <span
                                  style={{
                                    color: "rgb(136, 136, 136)",
                                    fontSize: 14,
                                    fontWeight: 400,
                                  }}
                                >
                                  {tutor.tutor.address &&
                                    `${tutor.tutor.address.area} ${tutor.tutor.address.city} ${tutor.tutor.address.state} ${tutor.tutor.address.pin}`}
                                </span>
                              </span>
                            </div>
                            <Rating
                              ratingValue={averageRatings}
                              readonly={true}
                              size={20}
                            />
                          </div>
                          <div className="tution-flex">
                            {tutor.tutor.teaching_preferences &&
                              tutor.tutor.teaching_preferences.home_tuition && (
                                <div className>
                                  <span>
                                    <i className="fa fa-home" /> Home Tuition
                                  </span>
                                </div>
                              )}

                            {tutor.tutor.teaching_preferences &&
                              tutor.tutor.teaching_preferences
                                .group_tuition && (
                                <div className>
                                  <span>
                                    <i className="fa fa-users" /> Group Tuition
                                  </span>
                                </div>
                              )}
                            {tutor.tutor.teaching_preferences &&
                              tutor.tutor.teaching_preferences
                                .online_tuition && (
                                <div className>
                                  <span>
                                    <i className="fa fa-laptop" /> Online
                                    Tuition
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="taught">
                            <p>
                              {tutor.tutor.profile &&
                              tutor.tutor.profile.short_profile
                                ? tutor.tutor.profile.short_profile
                                : ""}
                            </p>
                          </div>
                          <div className="experience-flex">
                            <div className="graduate-f">
                              <div>
                                <img src={Graduate} />
                              </div>
                              <div>
                                <h3>Experience</h3>
                                <h5>
                                  {" "}
                                  {tutor.tutor.teaching_profile &&
                                  tutor.tutor.teaching_profile
                                    .total_teaching_experience
                                    ? tutor.tutor.teaching_profile
                                        .total_teaching_experience
                                    : ""}{" "}
                                  Years
                                </h5>
                              </div>
                            </div>
                            <div className="graduate-f">
                              <div>
                                <img src={Subject} />
                              </div>
                              <div>
                                <h3>Subjects</h3>
                                <h5>
                                  {" "}
                                  {tutor.tutor.subjects &&
                                  tutor.tutor.subjects.subject
                                    ? tutor.tutor.subjects.subject
                                    : ""}{" "}
                                </h5>
                              </div>
                            </div>
                            <div className="graduate-f">
                              <div>
                                <img src={category} />
                              </div>
                              <div>
                                <h3>Category</h3>
                                <h5>
                                  {" "}
                                  {tutor.tutor.subjects &&
                                  tutor.tutor.subjects.category
                                    ? tutor.tutor.subjects.category
                                    : ""}{" "}
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/* table */}
                          <div className="single-category">
                            <table className="table table-striped  mb-4 detailPageTable">
                              <thead>
                                <tr>
                                  <th>Categories</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong lang="en-in">
                                      {" "}
                                      {tutor.tutor.subjects &&
                                      tutor.tutor.subjects.category
                                        ? tutor.tutor.subjects.category
                                        : "No Category available for Tutor"}{" "}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="following">
                            <h3>Teaches Following Boards/Degrees</h3>
                            <ul className="list mb-5">
                              {tutor.tutor.subjects &&
                              tutor.tutor.subjects.board
                                ? tutor.tutor.subjects.board &&
                                  tutor.tutor.subjects.board.split(",") &&
                                  tutor.tutor.subjects.board
                                    .split(",")
                                    .map((item) => {
                                      return (
                                        <li>
                                          <i className="fa fa-check mr-2" />
                                          {item}
                                        </li>
                                      );
                                    })
                                : ""}{" "}
                            </ul>
                          </div>
                          <hr />
                          <div className="following">
                            <h3>Teaches Following Specializations</h3>
                            <ul className="list mb-5">
                              {tutor.tutor.subjects &&
                              tutor.tutor.subjects.specialization ? (
                                tutor.tutor.subjects.specialization &&
                                tutor.tutor.subjects.specialization.split(
                                  ","
                                ) &&
                                tutor.tutor.subjects.specialization
                                  .split(",")
                                  .map((item) => {
                                    return (
                                      <li>
                                        <i className="fa fa-check mr-2" />
                                        {item}
                                      </li>
                                    );
                                  })
                              ) : (
                                <p>
                                  Tutor has not specified any specializations
                                  yet
                                </p>
                              )}{" "}
                            </ul>
                          </div>
                          <hr />
                          <div className="tutor-available">
                            <h3> Tutor's Availability Details</h3>
                            <div className=" main-single-tu">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="travelling">
                                    <div className="d-flex">
                                      <i className="fa fa-2x fa-map-signs "></i>
                                      <h3>Travelling Preference</h3>
                                    </div>
                                    {tutor.tutor.teaching_preferences &&
                                    tutor.tutor.teaching_preferences
                                      .travel_to_student_place ? (
                                      <p>Tutor can travel to your home</p>
                                    ) : (
                                      <p>
                                        {" "}
                                        <del>
                                          {" "}
                                          Tutor can travel to your home{" "}
                                        </del>
                                      </p>
                                    )}
                                    {tutor.tutor.teaching_preferences &&
                                    tutor.tutor.teaching_preferences
                                      .student_must_travel ? (
                                      <p> You can travel to Tutor's home</p>
                                    ) : (
                                      <p>
                                        {" "}
                                        <del>
                                          {" "}
                                          You can travel to Tutor's home{" "}
                                        </del>
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="travelling">
                                    <div className="d-flex">
                                      <i className="fa fa-building fa-2x "></i>
                                      <h3>Tutor Can Travel Upto</h3>
                                    </div>

                                    <p>
                                      {tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .max_travelling_distance
                                        ? tutor.tutor.teaching_preferences &&
                                          `${tutor.tutor.teaching_preferences.max_travelling_distance} Kilometeres`
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="travelling">
                                    <div className="d-flex">
                                      <i className="fa fa-clock-o fa-2x "></i>
                                      <h3>Tutor's Timing Availability</h3>
                                    </div>

                                    <p>
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_time &&
                                      tutor.tutor.teaching_preferences.prefer_time.includes(
                                        "Early Morning"
                                      ) ? (
                                        "Early Morning, "
                                      ) : (
                                        <del>Early Morning, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_time &&
                                      tutor.tutor.teaching_preferences.prefer_time.includes(
                                        "Morning"
                                      ) ? (
                                        "Morning, "
                                      ) : (
                                        <del>Morning, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_time &&
                                      tutor.tutor.teaching_preferences.prefer_time.includes(
                                        "Afternoon"
                                      ) ? (
                                        "Afternoon, "
                                      ) : (
                                        <del>Afternoon, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_time &&
                                      tutor.tutor.teaching_preferences.prefer_time.includes(
                                        "Evening"
                                      ) ? (
                                        "Evening, "
                                      ) : (
                                        <del>Evening, </del>
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="travelling">
                                    <div className="d-flex">
                                      <i className="fa fa-calendar fa-2x "></i>
                                      <h3>Tutor's Days Availability</h3>
                                    </div>

                                    <p>
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Monday"
                                      ) ? (
                                        "Monday, "
                                      ) : (
                                        <del>Monday, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Tuesday"
                                      ) ? (
                                        "Tuesday, "
                                      ) : (
                                        <del>Tuesday, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Wednesday"
                                      ) ? (
                                        "Wednesday, "
                                      ) : (
                                        <del>Wednesday, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Thursday"
                                      ) ? (
                                        "Thursday, "
                                      ) : (
                                        <del>Thursday, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Friday"
                                      ) ? (
                                        "Friday, "
                                      ) : (
                                        <del>Friday, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Saturday"
                                      ) ? (
                                        "Saturday, "
                                      ) : (
                                        <del>Saturday, </del>
                                      )}
                                      {tutor.tutor &&
                                      tutor.tutor.teaching_preferences &&
                                      tutor.tutor.teaching_preferences
                                        .prefer_days &&
                                      tutor.tutor.teaching_preferences.prefer_days.includes(
                                        "Sunday"
                                      ) ? (
                                        "Sunday, "
                                      ) : (
                                        <del>Sunday, </del>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="following">
                            <h3>
                              {" "}
                              About {tutor.first_name} {tutor.last_name}
                            </h3>

                            <p>
                              {tutor.tutor.profile &&
                              tutor.tutor.profile.description
                                ? tutor.tutor.profile.description
                                : ""}
                            </p>
                          </div>
                          <hr />
                          <div className="following">
                            <h3> Share My Profile</h3>

                            <p>
                              You can share this profile with someone lookng for
                              tuition.
                            </p>
                            <div className="share-pr">
                              <ul className="social">
                                <li>
                                  <a href="#">
                                    <i className="fa fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa fa-twitter" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa fa-linkedin" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr />

                          <ReviewForm tutor={tutor} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <div>
              <p> No Profile Available </p>
            </div>
          )}
        </>
      )}

      <Footer />
    </div>
  );
}

export default TutorDetails;
