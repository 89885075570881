import React from "react";
import AlertBox from "./AlertBox";

function Footer() {
  return (
    <div className="footer-section section">
      <div className="container">
        {/* Footer Widget Wrapper Start */}
        <div className>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              {/* Footer Widget Start */}
              <div className="footer-widget">
                <a className="footer-logo" href="index.html">
                  <h3>LOGO HERE</h3>
                </a>
                <div className="widget-info">
                  <p>
                    <i className="flaticon-phone-call" />
                    <a href="#">1234567890</a>
                  </p>
                  <p>
                    <i className="flaticon-placeholder" />
                    <a href="mailto:info@proextraclass.com">
                      info@proextraclass.com
                    </a>
                  </p>
                  <p>
                    <i className="flaticon-open-mail" />
                    <span>123, Main Street, Canada, USA</span>
                  </p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Widget End */}
            </div>
            <div className="col-lg-3 col-sm-6">
              {/* Footer Widget Start */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Board</h4>
                <div className="widget-link">
                  <ul className="link">
                    <li>
                      <a href="#">tate Board</a>
                    </li>
                    <li>
                      <a href="#">IGCSE Board</a>
                    </li>
                    <li>
                      <a href="#">IB Board</a>
                    </li>
                    <li>
                      <a href="#">CBSE Board</a>
                    </li>
                    <li>
                      <a href="#">ICSE Board</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Widget End */}
            </div>
            <div className="col-lg-3 col-sm-6">
              {/* Footer Widget Start */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Standard</h4>
                <div className="widget-link">
                  <ul className="link">
                    <li>
                      <a href="#">11th-12th Standard</a>
                    </li>
                    <li>
                      <a href="#">9th-10th Standard</a>
                    </li>
                    <li>
                      <a href="#">7-8th Standard</a>
                    </li>
                    <li>
                      <a href="#">5-6th Standard</a>
                    </li>
                    <li>
                      <a href="#">Primary Classes</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Widget End */}
            </div>
            <div className="col-lg-3 col-sm-6">
              {/* Footer Widget Start */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Useful Links</h4>
                <div className="widget-link">
                  <ul className="link">
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Contact &amp; Support</a>
                    </li>
                    <li>
                      <a href="#">Post Requirement</a>
                    </li>
                    <li>
                      <a href="#">Login</a>
                    </li>
                    <li>
                      <a href="#">Tutor FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Widget End */}
            </div>
          </div>
        </div>
        {/* Footer Widget Wrapper End */}
        {/* Footer Copyright End */}
        <div className="row">
          <div className="col-md-8">
            <div className="footer-copyright text-end">
              <p>© Copyrights 2021 Proextra All rights reserved.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-copyright text-end">
              <ul className="pp">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="#">Sitemap</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Footer Copyright End */}
      </div>
      <AlertBox />
    </div>
  );
}

export default Footer;
