export const inputFields = {
  address: {
    type: "string",
    required: true,
    title: "What's your address:",
    inputType: "text",
    col: 12,
  },
  pin: {
    type: "string",
    required: true,
    title: "Whats's your pincode:",
    inputType: "text",
  },
  area: {
    type: "string",
    required: true,
    title: "Enter your area:",
    inputType: "text",
  },
  city: {
    type: "string",
    required: true,
    title: "City:",
    inputType: "text",
  },
  state: {
    type: "select",
    required: true,
    title: "State",
    options: [
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
      { label: "Daman and Diu", value: "Daman and Diu" },
      { label: "Delhi", value: "Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "West Bengal", value: "West Bengal" },
    ],
  },
  spoken_language: {
    type: "select",
    title: "Languages you speak (Multiselect):",
    multiple: true,
    options: [
      {
        label: "English",
        value: "English",
      },
      {
        label: "Hindi",
        value: "Hindi",
      },
      {
        label: "Bengali",
        value: "Bengali",
      },
      {
        label: "Marathi",
        value: "Marathi",
      },
      {
        label: "Telugu",
        value: "Telugu",
      },
      {
        label: "Tamil",
        value: "Tamil",
      },
      {
        label: "Gujarati",
        value: "Gujarati",
      },
      {
        label: "Kannada",
        value: "Kannada",
      },
      {
        label: "Odia",
        value: "Odia",
      },
      {
        label: "Malayalam",
        value: "Malayalam",
      },
      {
        label: "Punjabi",
        value: "Punjabi",
      },
    ],
  },
  total_teaching_experience: {
    type: "select",
    required: true,
    title: "Total Teaching Experience",
    options: [
      {
        label: "Fresher",
        value: "Fresher",
      },
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6",
        value: "6",
      },
      {
        label: "7",
        value: "7",
      },
      {
        label: "8",
        value: "8",
      },
      {
        label: "9",
        value: "9",
      },
      {
        label: "10",
        value: "10",
      },
      {
        label: "10+",
        value: "10+",
      },
    ],
  },
};
export const initialValues = {
  address: "",
  pin: "",
  area: "",
  city: "",
  state: "",
  language: [],
  total_teaching_experience: "",
};

export const languages = [
  {
    label: "English",
    value: "English",
  },
  {
    label: "Hindi",
    value: "Hindi",
  },
  {
    label: "Bengali",
    value: "Bengali",
  },
  {
    label: "Marathi",
    value: "Marathi",
  },
  {
    label: "Telugu",
    value: "Telugu",
  },
  {
    label: "Tamil",
    value: "Tamil",
  },
  {
    label: "Gujarati",
    value: "Gujarati",
  },
  {
    label: "Kannada",
    value: "Kannada",
  },
  {
    label: "Odia",
    value: "Odia",
  },
  {
    label: "Malayalam",
    value: "Malayalam",
  },
  {
    label: "Punjabi",
    value: "Punjabi",
  },
];
export const languages2 = [
  "English",
  "Hindi",
  "Bengali",
  "Marathi",
  "Telugu",
  "Tamil",
  "Gujarati",
  "Kannada",
  "Odia",
  "Malayalam",
  "Punjabi",
];
