import {
  GET_USERS,
  ADD_USER,
  EDIT_USER,
  GET_USER,
  RESET_USER,
  GET_ALL_USERS,
  ERROR_USER,
} from "../types/user_type";

const initialState = {
  users: null,
  page: null,
  pages: null,
  user: null,
  total_users: 0,
  loading: true,
  error: {},
  user_message: null,
  all_users: null,
};

export const user_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS:
      return {
        ...state,
        users: payload.users,
        pages: payload.pages,
        page: payload.page,
        total_users: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_USERS:
      return {
        ...state,
        all_users: payload.users,
        loading: false,
        error: {},
      };

    case RESET_USER:
      return {
        ...state,
        user: null,
        loading: true,
        error: {},
      };

    case ADD_USER:
      return {
        ...state,
        user_message: payload,
        loading: false,
        error: {},
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
        loading: false,
        error: {},
      };
    case EDIT_USER:
      return {
        ...state,
        user_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_USER:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
