import React from "react";
import { Link } from "react-router-dom";
import HomeTuitionImage from "../../assets/images/event-1.jpg";

function HomeFeatured() {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="evenet-wrapper">
            <div className="row">
              <div className="col-lg-4">
                {/* Single Event Start */}
                <div className="single-event bg-1">
                  <div className="event-image">
                    <img src={HomeTuitionImage} alt="Event" />
                  </div>
                  <div className="event-content">
                    <h4 className="title">
                      <a href="#">Home Tuition </a>
                    </h4>
                    <div className="ht">
                      <Link
                        to="/tutors?tuition_type=home"
                        className="btn btn-primary btn-hover-heading-color"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Single Event End */}
              </div>
              <div className="col-lg-4">
                {/* Single Event Start */}
                <div className="single-event bg-2">
                  <div className="event-image">
                    <img src={HomeTuitionImage} alt="Event" />
                  </div>
                  <div className="event-content">
                    <h4 className="title">
                      <a href="#">Online Tuition </a>
                    </h4>
                    <div className="ht">
                      <Link
                        to="/tutors?tuition_type=online"
                        className="btn btn-primary btn-hover-heading-color"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Single Event End */}
              </div>
              <div className="col-lg-4">
                {/* Single Event Start */}
                <div className="single-event bg-3">
                  <div className="event-image">
                    <img src={HomeTuitionImage} alt="Event" />
                  </div>
                  <div className="event-content">
                    <h4 className="title">
                      <a href="#">Group Tuition </a>
                    </h4>
                    <div className="ht">
                      <Link
                        to="/tutors?tuition_type=group"
                        className="btn btn-primary btn-hover-heading-color"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Single Event End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeFeatured;
