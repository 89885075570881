import { URI } from "../domain/constant";
import axios from "axios";
export const upload = async (featuredImage) => {
  const newForm = new FormData();
  if (featuredImage) {
    newForm.append(`image`, featuredImage, featuredImage.name);
    const image = await axios.post(`${URI}/api/upload`, newForm);

    if (image.data) {
      return image.data;
    }
  }
};
export const uploadGallery = async (gallery) => {
  const newForm = new FormData();

  if (gallery) {
    Array.from(gallery).forEach((image, index) => {
      newForm.append(`gallery[${index}]`, image, image.name);
    });
    const image = await axios.post(`${URI}/api/upload/gallery`, newForm);

    if (image.data) {
      return image.data;
    }
  }
};
