import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/auth";
import Logo from "../../assets/images/logo.png";
function Header() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  const { isAuthenticated, user } = data;
  const [showDropdown, setShowDropdown] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="header" className="header section">
      <div className="container">
        {/* Header Wrapper Start  */}
        <div className="header-wrapper">
          {/* Header Logo Start */}
          <div className="header-logo">
            <Link to="/">
              <img src={Logo} alt="Pro Extra Class" />
            </Link>
          </div>

          <div className="header-meta">
            <div className="header-search d-none d-lg-block">
              <form action="#">
                <input type="text" placeholder="Search Courses" />
                <button>
                  <i className="flaticon-loupe" />
                </button>
              </form>
            </div>
            <div className="fr-de">
              <a href="#" className="btn btn-primary btn-hover-heading-color">
                Free Demo
              </a>
            </div>
            {!isAuthenticated ? (
              <div className="header-login d-none d-lg-flex">
                <Link className="link" to="/login">
                  <i className="fa fa-user-o" /> Login
                </Link>
                <Link className="link" to="/register">
                  Register
                </Link>
              </div>
            ) : (
              <>
                {user && (
                  <div
                    className="header-login d-none d-lg-flex"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <Link className="link" to="/login">
                      Hello {user.first_name} {user.last_name}{" "}
                      <i className="fa fa-user-o" />
                    </Link>
                    <ul
                      className="auth-menu"
                      style={{ display: showDropdown ? "block" : "none" }}
                    >
                      <li>
                        <Link
                          to={
                            user && user.user_type == "Tutor"
                              ? "/tutor/profile"
                              : user.user_type == "Student"
                              ? "/student/profile"
                              : "/institute/profile"
                          }
                        >
                          <i className="fa fa-user-o" /> View Profile{" "}
                        </Link>
                      </li>

                      <li>
                        <a onClick={() => dispatch(logout())}>
                          <i className="fa fa-sign-out" /> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
          {/* Header Meta End */}
        </div>
        {/* Header Wrapper End */}
      </div>
    </div>
  );
}

export default Header;
