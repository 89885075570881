import {
  GET_TUTORS,
  ADD_TUTOR,
  EDIT_TUTOR,
  GET_TUTOR,
  RESET_TUTOR,
  GET_ALL_TUTORS,
  ERROR_TUTOR,
} from "../types/tutor_type";

const initialState = {
  tutors: null,
  page: null,
  pages: null,
  tutor: null,
  total_tutors: 0,
  loading: true,
  error: {},
  tutor_message: null,
  all_tutors: null,
};

export const tutor_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TUTORS:
      return {
        ...state,
        tutors: payload.tutors,
        pages: payload.pages,
        page: payload.page,
        total_tutors: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_TUTORS:
      return {
        ...state,
        all_tutors: payload.tutors,
        loading: false,
        error: {},
      };

    case RESET_TUTOR:
      return {
        ...state,
        tutor: null,
        loading: true,
        error: {},
      };

    case ADD_TUTOR:
      return {
        ...state,
        tutor_message: payload,
        loading: false,
        error: {},
      };
    case GET_TUTOR:
      return {
        ...state,
        tutor: payload,
        loading: false,
        error: {},
      };
    case EDIT_TUTOR:
      return {
        ...state,
        tutor_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_TUTOR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
