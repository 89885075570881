import {
  GET_SUBJECTS,
  ADD_SUBJECT,
  EDIT_SUBJECT,
  GET_SUBJECT,
  RESET_SUBJECT,
  GET_ALL_SUBJECTS,
  ERROR_SUBJECT,
} from "../types/subject_type";

const initialState = {
  subjects: null,
  page: null,
  pages: null,
  subject: null,
  total_subjects: 0,
  loading: true,
  error: {},
  subject_message: null,
  all_subjects: null,
};

export const subject_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: payload.subjects,
        pages: payload.pages,
        page: payload.page,
        total_subjects: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_SUBJECTS:
      return {
        ...state,
        all_subjects: payload.subjects,
        loading: false,
        error: {},
      };

    case RESET_SUBJECT:
      return {
        ...state,
        subject: null,
        loading: true,
        error: {},
      };

    case ADD_SUBJECT:
      return {
        ...state,
        subject_message: payload,
        loading: false,
        error: {},
      };
    case GET_SUBJECT:
      return {
        ...state,
        subject: payload,
        loading: false,
        error: {},
      };
    case EDIT_SUBJECT:
      return {
        ...state,
        subject_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_SUBJECT:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
