import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../components/layout/Spinner";
import { loadUser } from "../../store/actions/auth";

const PrivateRoute = ({
  component: Component,
  loadUser,
  auth: { isAuthenticated, loading },
  ...rest
}) => {
  useEffect(() => {
    loadUser();
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <Spinner />
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = { loadUser };
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
