import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addTutor,
  getTutors,
  getTutor,
  addReview,
  deleteTutor,
} from "../../store/actions/tutor_action";
import { SEARCH_TERMS } from "../enums/tutors_enum";
import { useHistory, useLocation } from "react-router";
import { useQuery } from "../../domain/useQuery";
// Get All Data
export const useAllTutors = () => {
  const history = useHistory();
  const location = useLocation();
  let query = useQuery();
  console.log("QUERY", query.toString());
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tutor);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [q, setQ] = useState(null);
  const [term, setTerm] = useState(null);
  const [termField, setTermField] = useState(SEARCH_TERMS[0]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  useEffect(() => {
    async function allQuery() {
      if (deleteEntry) {
        await dispatch(deleteTutor(deleteEntry));
      }
      if (term && term.length > 0) {
        setQ(term);
        dispatch(
          getTutors({
            termField: termField ? termField : termField,
            term: q ? q : "",
            pageNumber,
            start_from: dateFrom,
            start_to: dateTo,
          })
        );
      } else {
        dispatch(
          getTutors({
            termField: termField ? termField : termField,
            term: q ? q : "",
            pageNumber,
            start_from: dateFrom,
            start_to: dateTo,
          })
        );
      }
    }
    allQuery();
  }, [deleteEntry, term, pageNumber, dateFrom, dateTo, window.location.search]);
  useEffect(() => {
    setPageNumber(1);
  }, [term, dateTo, dateFrom]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };
  const resetFilter = () => {
    setPageNumber(1);
    setQ(null);
    setTerm("");
    setDateFrom("");
    setDateTo("");
    dispatch(getTutors({}));
  };

  const handleSidebarChange = (name, value) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.set(name, value);
    // console.log("PARAMS", params.toString());
    history.push({ pathname: location.pathname, search: urlParams.toString() });
  };
  const removeSidebarParams = (name) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.delete(name);
    // console.log("PARAMS", params.toString());
    history.push({ pathname: location.pathname, search: urlParams.toString() });
  };

  return [
    data,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
    handleSidebarChange,
    removeSidebarParams,
  ];
};

// Get Single Data
export const useSingleTutor = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tutor);
  useEffect(() => {
    dispatch(getTutor(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateTutor = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addTutor(data));
  };
  return [addData];
};
export const useUpdateTutor = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(addReview(id, data));
  };
  return [updateData];
};
