import React, { useEffect, useState } from "react";
import HeaderProfile from "../../../components/template/HeaderProfile";
import SidebarStudent from "../../../components/template/SidebarStudent";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../../components/Form/Form";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/actions/auth";
import {
  useAllStates,
  useSearchPincode,
} from "../../../shared/hooks/UsePincodes";
function MyProfile() {
  const dispatch = useDispatch();
  const [states] = useAllStates();
  const [pincode, setPincode, pinCodeDetails] = useSearchPincode();
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [seletedSate, setSelectedSate] = useState("");
  const [selectedPincode, setSelectedPincode] = useState(null);

  const { user, loading } = useSelector((state) => state.auth);
  const submitFormClicked = async (values) => {
    const formData = {
      address: {
        address: values.address,
        pin: values.pincode,
        area: area,
        city: city,
        state: seletedSate,
      },
    };
    console.log(formData);
    await dispatch(
      updateProfile({
        student: formData,
        first_name: values.first_name,
        last_name: values.last_name,
        gender: values.gender,
      })
    );
  };

  useEffect(() => {
    if (pincode && pinCodeDetails && pincode.length === 6) {
      setSelectedPincode(pinCodeDetails);
    } else {
      setSelectedPincode(null);
      setArea("");
    }
  }, [pinCodeDetails, pincode]);
  useEffect(() => {
    if (selectedPincode) {
      const newArea =
        selectedPincode[0] && selectedPincode[0].area
          ? selectedPincode[0].area
          : "";
      setCity(
        selectedPincode && selectedPincode.length > 0
          ? selectedPincode[0].city
          : ""
      );
      setSelectedSate(
        selectedPincode && selectedPincode.length > 0
          ? selectedPincode[0].state
          : ""
      );
      setArea(newArea);
    }
  }, [selectedPincode]);

  useEffect(() => {
    if (user.student && user.student.address) {
      setArea(user.student.address.area);
      setCity(user.student.address.city);
      setSelectedSate(user.student.address.state);
    }
  }, [user.student]);

  return (
    <div className="main-wrapper">
      <HeaderProfile />
      <section className="profile-section">
        <SidebarStudent />
        {!loading && user ? (
          <div className="main-profile-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">Profile</div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          first_name: user.first_name,
                          last_name: user.last_name,
                          phone: user.phone,
                          gender: user.gender,
                          address:
                            user.student &&
                            user.student.address &&
                            user.student.address.address
                              ? user.student.address.address
                              : "",
                          pincode:
                            user.student &&
                            user.student.address &&
                            user.student.address.pin
                              ? user.student &&
                                user.student.address &&
                                user.student.address.pin
                              : "",
                        }}
                        validationSchema={Yup.object({
                          first_name: Yup.string().required("Required"),
                          last_name: Yup.string().required("Required"),
                          phone: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await submitFormClicked(values);
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="First Name"
                                    name="first_name"
                                    type="text"
                                    placeholder="Enter First Name"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Last Name"
                                    name="last_name"
                                    type="text"
                                    placeholder="Enter Last Name"
                                  />
                                </div>

                                <div className="col-md-6">
                                  <TextInput
                                    label="Phone"
                                    name="phone"
                                    type="text"
                                    placeholder="Enter Phone"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                    label="Gender"
                                    name="gender"
                                    placeholder="Enter Gender"
                                  >
                                    <option value=""> Select Gender </option>
                                    <option value="Male"> Male </option>
                                    <option value="Female"> Female </option>
                                    <option value="Others"> Others </option>
                                  </SelectBox>
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="What's Your Address:"
                                    name="address"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Whats's Your Pincode:"
                                    name="pincode"
                                    type="text"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "pincode",
                                        e.target.value
                                      );
                                      setPincode(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label> Area </label>
                                  <select
                                    label="Area"
                                    name="area"
                                    className="form-control"
                                    placeholder="Enter Area"
                                    value={area}
                                    onChange={(e) => setArea(e.target.value)}
                                  >
                                    <option value=""> Select Area </option>
                                    <option value={area}> {area} </option>
                                    {selectedPincode &&
                                      selectedPincode.map((item) => {
                                        return (
                                          <option value={item.area}>
                                            {item.area}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label> City </label>
                                    <input
                                      type="text"
                                      disabled
                                      className="form-control"
                                      value={city}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label> State </label>
                                    <input
                                      type="text"
                                      disabled
                                      className="form-control"
                                      value={seletedSate}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <button
                                      type="submit"
                                      className="btn btn-success"
                                    >
                                      {formik.isSubmitting ? (
                                        "Processing..."
                                      ) : (
                                        <>
                                          <i className="fa fa-refresh" /> Update
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> Loading... </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default MyProfile;
