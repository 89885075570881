import {
  GET_BOARDS,
  ADD_BOARD,
  EDIT_BOARD,
  GET_BOARD,
  RESET_BOARD,
  GET_ALL_BOARDS,
  ERROR_BOARD,
} from "../types/board_type";

const initialState = {
  boards: null,
  page: null,
  pages: null,
  board: null,
  total_boards: 0,
  loading: true,
  error: {},
  board_message: null,
  all_boards: null,
};

export const board_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BOARDS:
      return {
        ...state,
        boards: payload.boards,
        pages: payload.pages,
        page: payload.page,
        total_boards: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_BOARDS:
      return {
        ...state,
        all_boards: payload,
        loading: false,
        error: {},
      };

    case RESET_BOARD:
      return {
        ...state,
        board: null,
        loading: true,
        error: {},
      };

    case ADD_BOARD:
      return {
        ...state,
        board_message: payload,
        loading: false,
        error: {},
      };
    case GET_BOARD:
      return {
        ...state,
        board: payload,
        loading: false,
        error: {},
      };
    case EDIT_BOARD:
      return {
        ...state,
        board_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_BOARD:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
