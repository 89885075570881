import React from "react";
import { Link } from "react-router-dom";

function TutorSteps({ activeProfile }) {
  return (
    <div className="steps">
      <div
        className={
          activeProfile == "PROFILE" ? "step-item active-step" : "step-item "
        }
      >
        <Link to="/tutor/profile"> Step 1 </Link>
      </div>
      <div
        className={
          activeProfile == "SUBJECT" ? "step-item active-step" : "step-item "
        }
      >
        <Link to="/tutor/subjects"> Step 2 </Link>
      </div>
      <div
        className={
          activeProfile == "PREFERENCES"
            ? "step-item active-step"
            : "step-item "
        }
      >
        <Link to="/tutor/preferences"> Step 3 </Link>
      </div>
      <div
        className={
          activeProfile == "QUALIFICATION"
            ? "step-item active-step"
            : "step-item "
        }
      >
        <Link to="/tutor/qualifications"> Step 4 </Link>
      </div>
    </div>
  );
}

export default TutorSteps;
