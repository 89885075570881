import React from "react";
import Shape12 from "../../assets/images/shape/shape-12.svg";
import CTAImage from "../../assets/images/cta-1.png";
import { Link } from "react-router-dom";

function HomeActionSection() {
  return (
    <div className="section bg-color-2 call-to-action-bg">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-md-6">
            {/* Call To Action Content Start */}
            <div className="call-to-action-content-02 section-padding">
              {/* Section Title Start */}
              <div className="section-title">
                <h2 className="title mb-2">
                  Need a Tutor Urgently?
                  <br />
                  authored by Proextra Class
                </h2>
              </div>
              {/* Section Title End */}
              <p>
                Learners find best tutors on the same day on Proextra Class.
                Tell us your tuition requirement in less than a minute &amp; we
                will assist you in finding a matching tutor in less than 24
                hours!
              </p>
              <Link
                to="/tutors"
                className="btn btn-primary btn-hover-heading-color"
              >
                Get Started Today
              </Link>
            </div>
            {/* Call To Action Content End */}
          </div>
          <div className="col-md-6">
            {/* Call To Action Content Start */}
            <div className="call-to-action-images-02 text-center text-lg-end d-none d-md-block">
              <div className="call-to-images-shape-01" />
              <img
                className="call-to-images-shape-02 parallaxed"
                src={Shape12}
                alt
              />
              <div className="image">
                <div className="call-to-action-shape-01" />
                <img src={CTAImage} alt="Call To Action" />
              </div>
            </div>
            {/* Call To Action Content End */}
          </div>
        </div>
      </div>
      <div className="call-to-action-shape-01">
        <svg
          id="svg"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0, 0, 400,370.35681610247025"
        >
          <g id="svgg">
            <path
              className="shape-path"
              id="path0"
              d="M249.954 0.635 C 240.575 3.389,233.929 9.074,230.209 17.528 C 221.671 36.930,201.468 54.980,166.331 74.599 C 150.321 83.539,133.886 91.550,97.896 107.960 C 60.753 124.895,46.948 131.724,33.211 139.957 C 2.314 158.477,-5.531 173.697,7.558 189.731 C 8.181 190.495,11.285 193.759,14.456 196.984 C 38.336 221.280,50.229 238.507,53.680 253.797 C 54.907 259.234,55.006 266.165,53.979 274.748 C 51.984 291.423,54.149 301.599,62.854 316.468 C 72.588 333.095,83.951 343.673,97.896 349.088 C 107.191 352.698,115.551 354.028,136.688 355.260 C 160.933 356.672,172.530 358.640,186.647 363.738 C 199.456 368.363,216.007 367.283,240.657 360.214 C 277.351 349.690,331.341 324.707,386.917 292.536 C 397.502 286.408,398.745 285.641,398.645 285.304 C 398.595 285.134,396.907 276.638,394.895 266.423 C 371.016 145.183,358.578 74.176,357.552 53.239 C 357.423 50.608,357.277 49.322,356.993 48.307 C 352.156 31.013,314.465 7.243,281.287 0.562 L 278.494 0.000 265.276 0.009 L 252.059 0.017 249.954 0.635 "
              stroke="none"
              fillRule="evenodd"
            />
          </g>
        </svg>
      </div>
      <div className="call-to-action-shape-02 animate-05" />
      <div className="call-to-action-shape-03" />
    </div>
  );
}

export default HomeActionSection;
