import api from "../../domain/api";
import {
  SEARCH_BY_PIN,
  GET_STATE,
  GET_CITY,
  GET_AREA,
  GET_PINCODE,
  RESET_PINCODE,
} from "../types/pincode_type";

import { handleError } from "../../shared/handleError";

export const getAllState = () => async (dispatch, getState) => {
  try {
    const { data } = await api.get(`/pincodes/states`);
    dispatch({
      type: GET_STATE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getCityByState = (state) => async (dispatch, getState) => {
  try {
    const { data } = await api.get(`/pincodes/cities?state=${state}`);
    dispatch({
      type: GET_CITY,
      payload: data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getAreaByState = (state, city) => async (dispatch, getState) => {
  try {
    const { data } = await api.get(
      `/pincodes/areas?state=${state}&city=${city}`
    );
    dispatch({
      type: GET_AREA,
      payload: data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getDetailsByPincode = (pincode) => async (dispatch, getState) => {
  try {
    const { data } = await api.get(`/pincodes/${pincode}`);
    dispatch({
      type: SEARCH_BY_PIN,
      payload: data,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
